import axios from "axios";

// Fetch all comments for a specific blog post
export const fetchComments = async (blogId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/blogs/${blogId}/comments`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching comments:", error);
    throw error;
  }
};

// Submit a new comment or reply
export const submitComment = async (
  blogId,
  userId,
  comment,
  parentCommentId = null
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/blogs/${blogId}/comment`,
      {
        userId,
        comment,
        blogId, // Add the blogId to the payload
        parentCommentId, // Add parentCommentId if it's a reply
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting comment:", error);
    throw error;
  }
};

// Update comment
export const updateComment = async (commentId, newCommentText) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/comments/${commentId}`,
      {
        comment: newCommentText,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating comment:", error);
    throw error;
  }
};

// Delete comment
export const deleteComment = async (commentId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/comments/${commentId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error;
  }
};

// Like a comment
export const likeComment = async (commentId, userId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/comments/${commentId}/like`,
      {
        userId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error liking comment:", error);
    throw error;
  }
};

// Increment comment count for a blog post
export const incrementCommentCount = async (blogId) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/blogs/${blogId}/increment-comment-count`
    );
  } catch (error) {
    console.error("Error incrementing comment count:", error);
    throw error;
  }
};

// Decrement comment count for a blog post
export const decrementCommentCount = async (blogId) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/blogs/${blogId}/decrement-comment-count`
    );
  } catch (error) {
    console.error("Error decrementing comment count:", error);
    throw error;
  }
};

// Toggle like/unlike for a comment
export const toggleCommentLike = async (commentId, userId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/comments/${commentId}/like`,
      {
        userId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error toggling comment like:", error);
    throw error;
  }
};

// Report a comment
export const reportComment = async (commentId, reportData) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/comments/${commentId}/report`,
        reportData // Send report data in the request body
      );
      return response.data;
    } catch (error) {
      console.error("Error reporting comment:", error);
      throw error;
    }
  };
  
