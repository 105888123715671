import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CartProvider } from './context/CartContext';

const root = ReactDOM.createRoot(document.getElementById("page"));
root.render(
  <CartProvider>
    <CartProvider>
      <App />
    </CartProvider>
  </CartProvider>
);
