import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminAddProduct.css';
import ProductTable from '../ProductTable/ProductTable';
import { FaTimes } from 'react-icons/fa';

const AdminAddProduct = () => {
  const [product, setProduct] = useState({
    name: '',
    image: '',
    price: '',
    sale: false,
    oldPrice: '',
    link: '',
    rating: 0,
    categories: [],
    popular: false,
    popularity: 0,
    date: '',
    tags: [],
  });

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editProductId, setEditProductId] = useState(null);

  // Base URL for the API from environment variable
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/products`);
        setProducts(response.data);
        toast.success('Products fetched successfully.');
      } catch (error) {
        console.error('Error fetching products:', error);
        toast.error('Error fetching products.');
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Error fetching categories.');
      }
    };

    const fetchTags = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/tags`);
        setTags(response.data);
      } catch (error) {
        console.error('Error fetching tags:', error);
        toast.error('Error fetching tags.');
      }
    };

    fetchProducts();
    fetchCategories();
    fetchTags();
  }, [API_BASE_URL]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCategorySelect = (e) => {
    const selectedCategory = e.target.value;
    if (!product.categories.includes(selectedCategory)) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        categories: [...prevProduct.categories, selectedCategory],
      }));
    }
  };

  const handleTagSelect = (e) => {
    const selectedTag = e.target.value;
    if (!product.tags.includes(selectedTag)) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        tags: [...prevProduct.tags, selectedTag],
      }));
    }
  };

  const handleRemoveCategory = (categoryToRemove) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      categories: prevProduct.categories.filter(
        (category) => category !== categoryToRemove
      ),
    }));
  };

  const handleRemoveTag = (tagToRemove) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      tags: prevProduct.tags.filter((tag) => tag !== tagToRemove),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const productData = {
        ...product,
        tags: Array.isArray(product.tags) ? product.tags : product.tags.split(',').map(tag => tag.trim()),
        categories: Array.isArray(product.categories) ? product.categories : product.categories.split(',').map(category => category.trim())
      };
      if (isEditing) {
        await axios.put(
          `${API_BASE_URL}/products/${editProductId}`,
          productData
        );
        setIsEditing(false);
        setEditProductId(null);
        toast.success('Product updated successfully.');
      } else {
        await axios.post(`${API_BASE_URL}/products`, productData);
        toast.success('Product added successfully.');
      }
      setProduct({
        name: '',
        image: '',
        price: '',
        sale: false,
        oldPrice: '',
        link: '',
        rating: 0,
        categories: [],
        popular: false,
        popularity: 0,
        date: '',
        tags: [],
      });
      const response = await axios.get(`${API_BASE_URL}/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error saving product:', error);
      toast.error('Error saving product.');
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/products/${id}`
      );
      const productData = response.data;
      setProduct({
        ...productData,
        date: productData.date.split('T')[0], // Format date to yyyy-MM-dd
        tags: Array.isArray(productData.tags) ? productData.tags : productData.tags.split(',').map(tag => tag.trim()),
        categories: Array.isArray(productData.categories) ? productData.categories : productData.categories.split(',').map(category => category.trim())
      });
      setIsEditing(true);
      setEditProductId(id);
    } catch (error) {
      console.error('Error fetching product:', error);
      toast.error('Error fetching product.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/products/${id}`);
      setProducts(products.filter((product) => product.id !== id));
      toast.success('Product deleted successfully.');
    } catch (error) {
      console.error('Error deleting product:', error);
      toast.error('Error deleting product.');
    }
  };

  return (
    <div className="admin-add-product">
      <h1>{isEditing ? 'Edit Product' : 'Add New Product'}</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-columns">
          <div className="left-column">
            <div className="form-group">
              <label htmlFor="name">Product Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={product.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group inline-group">
              <div className="form-group-inline">
                <label htmlFor="image">Image URL</label>
                <input
                  type="text"
                  id="image"
                  name="image"
                  value={product.image}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group-inline">
                <label htmlFor="link">Product Link</label>
                <input
                  type="text"
                  id="link"
                  name="link"
                  value={product.link}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group inline-group">
              <div className="form-group-inline">
                <label htmlFor="oldPrice">Original Price</label>
                <input
                  type="number"
                  id="oldPrice"
                  name="oldPrice"
                  value={product.oldPrice}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-inline">
                <label htmlFor="sale">Sale</label>
                <select
                  id="sale"
                  name="sale"
                  value={product.sale ? 'yes' : 'no'}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: 'sale',
                        value: e.target.value === 'yes',
                      },
                    })
                  }
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="form-group-inline">
                <label htmlFor="price">Sales Price</label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={product.price}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group inline-group">
              <div className="form-group-inline">
                <label htmlFor="date">Sale Period</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={product.date}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="right-column">
            <div className="form-group inline-group">
              <div className="form-group-inline">
                <label htmlFor="popular">Popular</label>
                <select
                  id="popular"
                  name="popular"
                  value={product.popular ? 'yes' : 'no'}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: 'popular',
                        value: e.target.value === 'yes',
                      },
                    })
                  }
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="form-group-inline">
                <label htmlFor="popularity">Popularity</label>
                <input
                  type="number"
                  id="popularity"
                  name="popularity"
                  value={product.popularity}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group-inline">
                <label htmlFor="rating">Rating</label>
                <input
                  type="number"
                  id="rating"
                  name="rating"
                  value={product.rating}
                  onChange={handleChange}
                  required
                  min="0"
                  max="5"
                />
              </div>
            </div>
            <div className="form-group inline-group">
              <div className="form-group-inline">
                <label htmlFor="categories">Categories</label>
                <select
                  id="categories"
                  name="categories"
                  onChange={handleCategorySelect}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group-inline">
                <label htmlFor="tags">Tags</label>
                <select id="tags" name="tags" onChange={handleTagSelect}>
                  <option value="">Select Tag</option>
                  {tags.map((tag) => (
                    <option key={tag.id} value={tag.name}>
                      {tag.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group inline-group">
              <div className="form-group-inline">
                <label htmlFor="categories">Selected Categories</label>
                <div className="selected-items">
                  {product.categories.map((category, index) => (
                    <span key={index} className="selected-item">
                      {category}{' '}
                      <FaTimes onClick={() => handleRemoveCategory(category)} />
                    </span>
                  ))}
                </div>
              </div>
              <div className="form-group-inline">
                <label htmlFor="tags">Selected Tags</label>
                <div className="selected-items">
                  {product.tags.map((tag, index) => (
                    <span key={index} className="selected-item">
                      {tag} <FaTimes onClick={() => handleRemoveTag(tag)} />
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="submit">
          {isEditing ? 'Update Product' : 'Add Product'}
        </button>
      </form>
      <h1>All Products</h1>
      <ProductTable
        products={products}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <ToastContainer />
    </div>
  );
};

export default AdminAddProduct;
