import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  fetchBlogPost,
  fetchBlogs,
  fetchCategories,
  toggleBlogLike,
  checkIfUserLikedPost, // Import function to check if the user liked the post
} from "../utils/blogService";
import { useAuth } from "../context/AuthContext";
import DOMPurify from "dompurify";
import Comments from "../components/Comments/Comments";
import SharePost from "../components/SharePost/SharePost";

const Post = () => {
  const { postId } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [hasLiked, setHasLiked] = useState(false);

  const { auth } = useAuth();
  const { userId } = auth;

  useEffect(() => {
    const getBlogPost = async () => {
      try {
        const data = await fetchBlogPost(postId);
        const sanitizedContent = DOMPurify.sanitize(data.content);
        const sanitizedStyles = DOMPurify.sanitize(data.styles);
        setBlogPost({
          ...data,
          content: sanitizedContent,
          styles: sanitizedStyles,
        });

        if (userId) {
          const liked = await checkIfUserLikedPost(postId, userId);
          setHasLiked(liked); // Set hasLiked based on the server response
        }
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    const getRecentPosts = async () => {
      try {
        const data = await fetchBlogs();
        setRecentPosts(data.slice(0, 3));
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      }
    };

    const getCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    getBlogPost();
    getRecentPosts();
    getCategories();
  }, [postId, userId]);

  // Handle Like/Unlike functionality
  const handleLike = async () => {
    if (userId) {
      try {
        const updatedBlog = await toggleBlogLike(postId, userId);
        setBlogPost(updatedBlog); // Update the blog post with the new like count
        setHasLiked(!hasLiked); // Toggle the hasLiked state after successful like/unlike
      } catch (error) {
        console.error("Error toggling like:", error);
      }
    } else {
      console.log("User must be logged in to like/unlike posts.");
    }
  };

  // Function to update the comment count dynamically
  const updateCommentsCount = (newCount) => {
    setBlogPost((prevBlogPost) => ({
      ...prevBlogPost,
      comments_count: newCount,
    }));
  };

  if (!blogPost) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">{blogPost.title}</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Blog Post</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="entry-content">
        <div className="container">
          <div className="row">
            <div className="content-area col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <article className="blog-post post-box">
                <div className="entry-media">
                  <div className="post-cat">
                    <span className="posted-in">
                      {blogPost.categories &&
                        blogPost.categories
                          .split(",")
                          .map((category, index) => (
                            <Link key={index} to="#">
                              {category}
                            </Link>
                          ))}
                    </span>
                  </div>
                  <div className="entry-header">
                    <div className="entry-meta">
                      <span className="posted-on">
                        <Link to="#">
                          {new Date(blogPost.post_date).toLocaleDateString()}
                        </Link>
                      </span>
                      <span className="byline">
                        |{" "}
                        <Link className="url fn n" to="#">
                          {blogPost.author}
                        </Link>
                      </span>
                      <span className="comment-num">
                        | <Link to="#">{blogPost.comments_count} Comments</Link>
                      </span>
                    </div>
                    <h3 className="entry-title">{blogPost.title}</h3>
                  </div>
                  <img
                    src={`${process.env.REACT_APP_STATIC_BASE_URL}${blogPost.image_url}`}
                    alt={blogPost.title}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div className="inner-post">
                  <div className="entry-summary">
                    <div
                      dangerouslySetInnerHTML={{ __html: blogPost.content }}
                    />
                  </div>
                </div>
                <div className="entry-footer clearfix">
                  <div className="widget_tag_cloudd tagcloud">
                    {blogPost.tags &&
                      blogPost.tags.split(",").map((tag, index) => (
                        <Link key={index} to="#">
                          {tag}
                        </Link>
                      ))}
                  </div>
                  <span className="sl-wrapper">
                    <Link
                      to="#"
                      className="sl-button sl-button-1263"
                      title="Like"
                      onClick={handleLike}
                    >
                      <span className="sl-icon">
                        <i
                          className={hasLiked ? "fas fa-heart" : "far fa-heart"}
                        ></i>
                      </span>
                      <span className="sl-count">{blogPost.likes}</span>
                    </Link>
                  </span>
                </div>
                <SharePost
                  postUrl={`${process.env.REACT_APP_API_URL}/post/${postId}`}
                  postTitle={blogPost.title}
                  postDescription={blogPost.content}
                  postImageUrl={blogPost.image_url}
                />
                <Comments blogId={postId} updateCommentsCount={updateCommentsCount} />
              </article>
            </div>

            {/* Sidebar */}
            <div className="widget-area primary-sidebar col-lg-3 col-md-12 col-sm-12 col-xs-12">
              <aside className="widget widget_recent_posts">
                <h3 className="widget-title">Most Recent Posts</h3>
                <ul className="recent-posts-list">
                  {recentPosts.map((post) => (
                    <li key={post.id}>
                      <Link to={`/post/${post.id}`}>{post.title}</Link>
                      <p className="recent-post-date">
                        {new Date(post.post_date).toLocaleDateString()}
                      </p>
                    </li>
                  ))}
                </ul>
              </aside>

              <aside className="widget widget_categories">
                <h3 className="widget-title">Categories</h3>
                <ul className="categories-list">
                  {categories.map((category) => (
                    <li key={category.id}>
                      <Link to={`/category/${category.name}`}>
                        {category.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </aside>
            </div>
          </div>
        </div>
      </div>
      {blogPost.styles && (
        <style dangerouslySetInnerHTML={{ __html: blogPost.styles }} />
      )}
    </div>
  );
};

export default Post;
