import React, { useState } from "react";
import "./ModalReportComment.css";

const ModalReportComment = ({ isOpen, onClose, onSubmit, commentId, blogId }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [additionalReason, setAdditionalReason] = useState("");

  // Possible reasons for reporting
  const reportReasons = [
    "Spam",
    "Inappropriate Content",
    "Harassment",
    "False Information",
    "Other",
  ];

  // Handle radio button selection
  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  // Handle additional comments input
  const handleAdditionalReasonChange = (e) => {
    setAdditionalReason(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Ensure a reason is selected before submitting
    if (!selectedReason) {
      alert("Please select a reason for reporting.");
      return;
    }

    // Log the selected reason for debugging
    console.log("Selected reason:", selectedReason);

    // Call the onSubmit function provided via props to send data to the backend
    onSubmit({
      selectedReason,
      additionalReason,
      commentId,
      blogId,
    });

    // Reset the modal state and close it
    setSelectedReason("");
    setAdditionalReason("");
    onClose();
  };

  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Report Comment</h2>
        <form onSubmit={handleSubmit}>
          <div className="report-reason">
            <h3 className="report-subheading">Select a reason for reporting:</h3>
            {reportReasons.map((reason, index) => (
              <label key={index} className="radio-label">
                <input
                  type="radio"
                  name="reason"
                  value={reason}
                  checked={selectedReason === reason}
                  onChange={handleReasonChange}
                  required
                />
                {reason}
              </label>
            ))}
          </div>

          {selectedReason === "Other" && (
            <div className="additional-reason">
              <h3>Explain why you're reporting this comment:</h3>
              <textarea
                value={additionalReason}
                onChange={handleAdditionalReasonChange}
                placeholder="Please provide additional details..."
                rows="4"
              />
            </div>
          )}

          <div className="modal-actions">
            <button type="submit" className="submit-button">
              Submit Report
            </button>
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalReportComment;
