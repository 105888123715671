import React from "react";
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div>
      {" "}
      <footer id="site-footer" className="site-footer footer-v2">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src="images/logo.png" alt="" />
            </div>
          </div>
          <div className="space-60"></div>
          <div className="row justify-content-center">
            <div className="col-md-4 col-sm-6">
              <div className="contact-info box-style2 ft-contact-info">
                <div className="box-icon">
                  <i className="flaticon-world-globe"></i>
                </div>
                <h6>Our Address</h6>
                <p>8 Jonker Street, Alberton, Johannesurg</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="contact-info box-style2 ft-contact-info">
                <div className="box-icon">
                  <i className="flaticon-envelope"></i>
                </div>
                <h6>Our Mailbox</h6>
                <p>info@toris.co.za</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="contact-info box-style2 ft-contact-info">
                <div className="box-icon">
                  <i className="flaticon-phone-1"></i>
                </div>
                <h6>Our Phone</h6>
                <p>+27 81 297 6016</p>
              </div>
            </div>
          </div>
          <div className="space-65"></div>
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="footer-menu">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/portfolio">Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contacts</Link>
                  </li>
                </ul>
              </div>
              <div className="space-20"></div>
              <p className="copyright-text v2">
                Copyright © 2024 TORIS Technologies. All Rights Reserved.
              </p>
              <div className="space-26"></div>
              <div className="ft-list-icon">
                <Link
                  className="facebook"
                  to="https://www.facebook.com/TORIS.Tech"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link
                  className="linkedin"
                  to="https://www.linkedin.com/company/42849416/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </Link>
                <Link
                  className="instagram"
                  to="https://www.instagram.com/toris.technologies/"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Link id="back-to-top" to="#" className="show"><i className="flaticon-up-arrow"></i></Link>
      </footer>
    </div>
  );
};
