import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlogs } from '../utils/blogService';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const loadBlogs = async () => {
      try {
        const data = await fetchBlogs();
        setBlogs(data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };
  
    loadBlogs();
  }, []);
  

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div>
      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Blog Grid</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li><Link to="/">Home</Link></li>
                <li className="active">Blog Grid</li>
              </ul>    
            </div>
          </div>
        </div>
      </div>

      <div className="entry-content">
        <div className="container">
          <div className="blog-grid pgrid">
            <div className="row">
              {blogs.map((blog) => (
                <div className="col-lg-4 col-md-6 col-sm-12" key={blog.id}>
                  <article className="post-box blog-item">
                    <div className="post-inner">
                      <div className="entry-media">
                        <div className="post-cat">
                          <span className="posted-in">
                            <Link to="#" rel="category tag">{blog.category}</Link>
                          </span>
                        </div>
                        <Link to={`/post/${blog.id}`}>
                          {/* Construct the full image URL */}
                          <img
                            src={`${process.env.REACT_APP_STATIC_BASE_URL}${blog.image_url}`}
                            alt={blog.title}
                          />
                        </Link>
                      </div>
                      <div className="inner-post">
                        <div className="entry-header">
                          <div className="entry-meta">
                            <span className="posted-on"><Link to="#">{formatDate(blog.post_date)}</Link></span>
                            <span className="byline"> | <Link className="url fn n" to="#">{blog.author}</Link></span>
                            <span className="comment-num"> | <Link to="#"> {blog.comments_count} Comments</Link></span>
                          </div>
                          <h3 className="entry-title">
                            <Link to={`/post/${blog.id}`}>{blog.title}</Link>
                          </h3>
                        </div>
                        <div className="btn-readmore">
                          <Link to={`/post/${blog.id}`}><i className="flaticon-right-arrow-1"></i>LEARN MORE</Link>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              ))}
            </div>
          </div>
          <div className="ot-button text-center">
            <Link to="#" className="octf-btn octf-btn-primary">Load More</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
