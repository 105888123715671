import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa'; // Import the trash can icon
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import './AdminUsersManagement.css';

const AdminUsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Base URL for the API from environment variable
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users`);
      setUsers(response.data);
      toast.success('Users fetched successfully.');
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Error fetching users.');
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/users/register`, {
        username,
        email,
        password
      });
      setUsername('');
      setEmail('');
      setPassword('');
      fetchUsers(); // Refresh the users list
      toast.success('User created successfully!'); // Show success toast
    } catch (error) {
      console.error('Error creating user:', error);
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data.message === 'Email already in use') {
            toast.error('Email already in use'); // Show error toast if email already exists
          } else if (error.response.data.message === 'Username already in use') {
            toast.error('Username already in use'); // Show error toast if username already exists
          } else {
            toast.error('Error creating user'); // General error toast
          }
        } else {
          toast.error('Error creating user'); // General error toast
        }
      } else {
        toast.error('Error creating user'); // General error toast
      }
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/users/delete/${id}`);
      fetchUsers(); // Refresh the users list after deleting
      toast.success('User deleted successfully!'); // Show success toast
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Error deleting user'); // General error toast
    }
  };
  

  return (
    <div className="admin-users-management">
      <ToastContainer /> {/* Add ToastContainer to your component */}
      <h1>Admin Users Management</h1>
      <form onSubmit={handleCreateUser} className="create-user-form">
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Create User</button>
      </form>

      <h1>Existing Users</h1>
      <table className="users-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th className="button-column">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td className="button-column">
                <button onClick={() => handleDeleteUser(user.id)} className="btn btn-danger">
                  <FaTrash /> {/* Replace Delete text with the trash can icon */}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminUsersManagement;
