import React, { useState } from "react";
import Modal from "react-modal"; // Import the modal
import {
  FaUserPlus,
  FaCartPlus,
  FaList,
  FaTags,
  FaEnvelope,
  FaBook,
  FaComment,
} from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./AdminSidebar.css";
import "../Tooltip/Tooltip.css";

// Modal styling (optional customization)
const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px", // Wider for a modern, spacious feel
    padding: "20px",
    borderRadius: "12px", // More rounded corners for a modern look
    border: "none", // Remove the default border
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
    backgroundColor: "#fff", // Ensure it's clean and white
    zIndex: 9999,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Dark overlay for a modal backdrop effect
    zIndex: 9998,
  },
};

const AdminSidebar = ({ setSelectedComponent }) => {
  const [activeItem, setActiveItem] = useState("usersManagement");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

  const handleItemClick = (component) => {
    setActiveItem(component);
    setSelectedComponent(component);
    closeModal(); // Close modal if an item is clicked inside modal
  };

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className="admin-sidebar">
      <ul>
        <li
          className={activeItem === "usersManagement" ? "active" : ""}
          onClick={() => handleItemClick("usersManagement")}
          data-tooltip-id="tooltip-users"
          data-tooltip-content="Manage Users"
        >
          <FaUserPlus />
        </li>
        <li
          className={activeItem === "addProduct" ? "active" : ""}
          onClick={() => handleItemClick("addProduct")}
          data-tooltip-id="tooltip-addProduct"
          data-tooltip-content="Add Product"
        >
          <FaCartPlus />
        </li>
        <li
          className={activeItem === "productCategories" ? "active" : ""}
          onClick={() => handleItemClick("productCategories")}
          data-tooltip-id="tooltip-categories"
          data-tooltip-content="Manage Categories"
        >
          <FaList />
        </li>
        <li
          className={activeItem === "productTags" ? "active" : ""}
          onClick={() => handleItemClick("productTags")}
          data-tooltip-id="tooltip-tags"
          data-tooltip-content="Manage Tags"
        >
          <FaTags />
        </li>
        <li
          className={activeItem === "viewEnquiries" ? "active" : ""}
          onClick={() => handleItemClick("viewEnquiries")}
          data-tooltip-id="tooltip-enquiries"
          data-tooltip-content="View Enquiries"
        >
          <FaEnvelope />
        </li>

        {/* Blogs with Modal */}
        <li
          className={activeItem.startsWith("blog") ? "active" : ""}
          onClick={openModal} // Open the modal on click
          data-tooltip-id="tooltip-blogs"
          data-tooltip-content="Manage Blogs"
        >
          <FaBook />
        </li>

        {/* Comment Moderation */}
        <li
          className={activeItem === "moderateComments" ? "active" : ""}
          onClick={() => handleItemClick("moderateComments")}
          data-tooltip-id="tooltip-moderateComments"
          data-tooltip-content="Moderate Comments"
        >
          <FaComment />
        </li>

        {/* Tooltip Components */}
        <ReactTooltip
          id="tooltip-users"
          place="right"
          effect="solid"
          className="custom-tooltip"
        />
        <ReactTooltip
          id="tooltip-addProduct"
          place="right"
          effect="solid"
          className="custom-tooltip"
        />
        <ReactTooltip
          id="tooltip-categories"
          place="right"
          effect="solid"
          className="custom-tooltip"
        />
        <ReactTooltip
          id="tooltip-tags"
          place="right"
          effect="solid"
          className="custom-tooltip"
        />
        <ReactTooltip
          id="tooltip-enquiries"
          place="right"
          effect="solid"
          className="custom-tooltip"
        />
        <ReactTooltip
          id="tooltip-blogs"
          place="right"
          effect="solid"
          className="custom-tooltip"
        />
        <ReactTooltip
          id="tooltip-moderateComments"
          place="right"
          effect="solid"
          className="custom-tooltip"
        />
      </ul>

      {/* Modal for Blog Options */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Manage Blogs"
        style={customModalStyles}
        ariaHideApp={false} // Add this to remove warnings in dev mode
      >
        <div className="modal-header">
          <h3>Manage Blogs</h3>
        </div>
        <ul className="list-options">
          <li
            className={activeItem === "viewBlogs" ? "active" : ""}
            onClick={() => handleItemClick("viewBlogs")}
          >
            View Blogs
          </li>
          <li
            className={activeItem === "addBlog" ? "active" : ""}
            onClick={() => handleItemClick("addBlog")}
          >
            Add Blog
          </li>
        </ul>
        <div className="modal-footer">
          <button onClick={closeModal}>Close</button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminSidebar;
