import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
  return (
    <div>
        <div id="content" className="site-content">
            <div className="page-header flex-middle">
                <div className="container">
                    <div className="inner flex-middle">
                        <h1 className="page-title">Portfolio Masonry</h1>
                        <ul id="breadcrumbs" className="breadcrumbs none-style">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">Portfolio Masonry</li>
                        </ul>    
                    </div>
                </div>
            </div>
            <section className="projects-masonry">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="ot-heading">
                                <span>latest case studies</span>
                                <h2 className="main-heading">Introduce Our Projects</h2>
                            </div>
                            <div className="space-5"></div>
                            <p>Software development outsourcing is just a tool to achieve business goals. But there is no way<br />to get worthwhile results without cooperation and trust between a client company.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="project-filter-wrapper">
                                <ul className="project_filters">
                                    <li><Link to="#" data-filter="*" className="selected">All</Link></li>
                                    <li><Link to="#" data-filter=".design">Design</Link></li>                       
                                    <li><Link to="#" data-filter=".development">Development</Link></li>                     
                                    <li><Link to="#" data-filter=".ideas">Ideas</Link></li>                     
                                    <li><Link to="#" data-filter=".technology">Technology</Link></li>                       
                                </ul>
                                <div className="projects-grid-ms projects-grid projects-style-2 projects-col3">

                                    <div className="project-item design ideas">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project1.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">App for Virtual Reality</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Design</Link><span>/</span>
                                                        <Link to="#">Ideas</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item technology development">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project2.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Mobile Coin View App</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Technology</Link><span>/</span>
                                                        <Link to="#">Development</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item ideas technology">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project3.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Analysis of Security</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Ideas</Link><span>/</span>
                                                        <Link to="#">Technology</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item design development">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project4.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">eCommerce Website</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Design</Link><span>/</span>
                                                        <Link to="#">Development</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item design ideas">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project5.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Responsive Design</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Design</Link><span>/</span>
                                                        <Link to="#">Ideas</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item development ideas">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project6.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">App for Health</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Development</Link><span>/</span>
                                                        <Link to="#">Ideas</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item design technology">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project7.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Basics Project</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Design</Link><span>/</span>
                                                        <Link to="#">Technology</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item technology development">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project8.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Social Media App</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Technology</Link><span>/</span>
                                                        <Link to="#">Development</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item design development">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project9.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Your New Reality</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Design</Link><span>/</span>
                                                        <Link to="#">Development</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item development ideas">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project10.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Immersive Experiencey</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Design</Link><span>/</span>
                                                        <Link to="#">Ideas</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item design technology">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project11.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Corporate Website</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Design</Link><span>/</span>
                                                        <Link to="#">Technology</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-item technology ideas">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/masonry/project12.jpg`} alt=""  />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Crypto App Project</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Technology</Link><span>/</span>
                                                        <Link to="#">Ideas</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="space-60"></div>
                            <div className="ot-button text-center">
                                <Link to="/portfolio" className="octf-btn octf-btn-primary">Load More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
  )
}

export default Portfolio;