import React, { useState, useEffect, useRef } from "react";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaListUl,
  FaListOl,
  FaParagraph,
  FaHeading,
  FaImage,
  FaLink,
  FaVideo,
} from "react-icons/fa"; // Importing icons
import axios from "axios";
import Modal from "react-modal";
import DOMPurify from "dompurify"; // Importing DOMPurify for sanitization
import "./AdminAddBlog.css";

const AdminAddBlog = ({ blogToEdit }) => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [image, setImage] = useState(null);
  const [customStyles, setCustomStyles] = useState(""); // To store custom CSS
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const textareaRef = useRef();

  useEffect(() => {
    if (blogToEdit) {
      setTitle(blogToEdit.title);
      setCategory(blogToEdit.category);
      setContent(blogToEdit.content);
      setAuthor(blogToEdit.author);
      setCustomStyles(blogToEdit.styles || ""); // Load custom styles if editing
      setIsEditing(true);
    }
  }, [blogToEdit]);

  // Function to sanitize HTML and CSS
  const sanitizeHTML = (htmlContent) => {
    return DOMPurify.sanitize(htmlContent);
  };

  // Function to wrap selected text and move cursor to a new line
  const wrapSelectedText = (tag, attributes = "") => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = content.slice(start, end);

    // Insert element and move cursor to a new line after insertion
    let newContent =
      content.slice(0, start) +
      `<${tag} ${attributes.trim()}>${selectedText}</${tag}>\n` +
      content.slice(end);

    setContent(newContent);

    // Move the cursor to the end of the newly inserted element
    const newCursorPosition = start + `<${tag} ${attributes.trim()}>${selectedText}</${tag}>\n`.length;
    setTimeout(() => {
      textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      textareaRef.current.focus(); // Focus on the textarea
    }, 0);

    setIsModalOpen(false); // Close the modal after the element is inserted
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedContent = sanitizeHTML(content);
    const sanitizedStyles = sanitizeHTML(customStyles);
  
    const formData = new FormData();
    formData.append("title", title);
    formData.append("category", category);
    formData.append("author", author);
    formData.append("content", sanitizedContent);
    formData.append("styles", sanitizedStyles);
    if (image) formData.append("image", image); // Attach image file to formData
  
    try {
      if (isEditing) {
        await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/blogs/${blogToEdit.id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" }, // Ensure multipart/form-data is used
          }
        );
        alert("Blog updated successfully");
      } else {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/blogs`, formData, {
          headers: { "Content-Type": "multipart/form-data" }, // Ensure multipart/form-data is used
        });
        alert("Blog added successfully");
      }
    } catch (error) {
      console.error("Error submitting blog:", error);
      alert("Failed to submit blog");
    }
  };
  

  return (
    <div className="admin-blog-layout">
      <div className="admin-add-blog">
        <h2>{isEditing ? "Edit Blog" : "Add a New Blog"}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Content:</label>
            <div className="text-toolbar">
              <button
                type="button"
                className="toolbar-btn"
                onClick={() => wrapSelectedText("strong")}
                title="Bold"
              >
                <FaBold />
              </button>
              <button
                type="button"
                className="toolbar-btn"
                onClick={() => wrapSelectedText("em")}
                title="Italic"
              >
                <FaItalic />
              </button>
              <button
                type="button"
                className="toolbar-btn"
                onClick={() => wrapSelectedText("u")}
                title="Underline"
              >
                <FaUnderline />
              </button>
            </div>
            <textarea
              ref={textareaRef}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
              className="form-control large-textarea"
              onContextMenu={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }} // Right-click opens modal
            />
          </div>
          <button type="submit" className="submit-btn">
            {isEditing ? "Update Blog" : "Add Blog"}
          </button>
        </form>
      </div>

      <div className="editor-sidebar">
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Category:</label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Author:</label>
          <input
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Featured Image:</label>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            className="dialog-form-control"
          />
        </div>

        {/* Custom Styles */}
        <div className="form-group">
          <label>Custom Styles (CSS):</label>
          <textarea
            value={customStyles}
            onChange={(e) => setCustomStyles(e.target.value)}
            className="form-control"
            placeholder="Enter custom CSS for this blog"
            rows="6"
          />
        </div>
      </div>

      {/* Modal for inserting new elements */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            height: '400px',
            padding: '20px',
            borderRadius: '10px',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
        ariaHideApp={false}
      >
        <h3>Insert Element</h3>
        <div className="element-options">
          <div className="element-item" onClick={() => wrapSelectedText("p", `class=""`)}>
            <FaParagraph />
            <span>Paragraph</span>
          </div>
          <div className="element-item" onClick={() => wrapSelectedText("h1", `class=""`)}>
            <FaHeading />
            <span>Heading 1</span>
          </div>
          <div className="element-item" onClick={() => wrapSelectedText("h2", `class=""`)}>
            <FaHeading />
            <span>Heading 2</span>
          </div>
          <div className="element-item" onClick={() => wrapSelectedText("h3", `class=""`)}>
            <FaHeading />
            <span>Heading 3</span>
          </div>
          <div className="element-item" onClick={() => wrapSelectedText("ul", `class=""`)}>
            <FaListUl />
            <span>Unordered List</span>
          </div>
          <div className="element-item" onClick={() => wrapSelectedText("ol", `class=""`)}>
            <FaListOl />
            <span>Ordered List</span>
          </div>
          <div className="element-item" onClick={() => wrapSelectedText("img", `class="" src=""`)}>
            <FaImage />
            <span>Image</span>
          </div>
          <div className="element-item" onClick={() => wrapSelectedText("video", `class="" src=""`)}>
            <FaVideo />
            <span>Video</span>
          </div>
          <div className="element-item" onClick={() => wrapSelectedText("a", `href="" target="_blank"`)}>
            <FaLink />
            <span>Link</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminAddBlog;
