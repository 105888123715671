import React from "react";
import { Link } from 'react-router-dom';
import './HomeBanner.css';

export const HomeBanner = () => {
  return (
    <div>
      <section className="home2-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center text-lg-left mb-4 logo-container">
              <img src={`${process.env.PUBLIC_URL}/assets/images/toris-logo-transparent.png`} alt="Toris Technologies Logo" />
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="home2-top-right">
                <p className="small-text">Functional, Usability & Performance</p>
                <h2>SOFTWARE DEVELOPMENT</h2>
                <p className="sub-text">
                  We transform businesses of most major sectors with powerful
                  and adaptable digital solutions that satisfy the needs of
                  today.
                </p>
                {/* <div className="video-popup style-2">
                  <div className="btn-inner">
                    <Link className="btn-play" to="https://vimeo.com/94686522">
                      <i className="flaticon-play"></i>
                      <span className="circle-1"></span>
                      <span className="circle-2"></span>
                    </Link>
                  </div>
                  <span>video showcase</span>
                </div> */}
                {/* <div className="login-icon">
                  <Link to="/login">
                    <i className="fa fa-user"></i> Login
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
