import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaSignInAlt, FaSignOutAlt  } from 'react-icons/fa';
import { MdDashboard } from "react-icons/md";
import $ from 'jquery';
import { useCart } from '../../context/CartContext';
import { useAuth } from '../../context/AuthContext';
import './Navbar.css';

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { cart, dispatch } = useCart();
  const { auth, logout } = useAuth(); // Access auth context

  useEffect(() => {
    const handleMobileMenuToggle = () => {
      setMenuOpen(prevMenuOpen => !prevMenuOpen);
      console.log('mobile menu clicked');
    };

    $('#mmenu_toggle').on('click', handleMobileMenuToggle);

    // Clean up the event listener when the component unmounts
    return () => {
      $('#mmenu_toggle').off('click', handleMobileMenuToggle);
    };
  }, []);

  useEffect(() => {
    // Apply the active class based on the menu state
    if (menuOpen) {
      $('.mobile_nav').stop(true, true).slideDown();
      $('.header_mobile').addClass('active');
    } else {
      $('.mobile_nav').stop(true, true).slideUp();
      $('.header_mobile').removeClass('active');
    }
  }, [menuOpen]);

  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0);

  const handleRemoveFromCart = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id } });
  };

  return (
    <div>
      <header id="site-header" className="site-header header-style-2 header-fullwidth sticky-header header-static">
        {/* Main Header start */}
        <div className="header-topbar">
          <div className="octf-area-wrap">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <ul className="topbar-info">
                    <li><i className="fas fa-envelope"></i><Link to="mailto:info@toris.co.za"> info@toris.co.za</Link></li>
                    <li><i className="fas fa-clock"></i> Mon - Fri: 8:00 am - 5:00 pm</li>
                  </ul>
                </div>
                <div className="col-md-6 text-right">
                  <div className="topbar-right">
                    <ul className="extra-text">
                      <li>We are creative, ambitious and ready for challenges! <Link to="/contact">Hire Us</Link></li>
                    </ul>
                    <ul className="social-list">
                      <li><Link to="https://www.facebook.com/TORIS.Tech" target="_self"><i className="fab fa-facebook-f"></i></Link></li>
                      <li><Link to="https://www.linkedin.com/company/42849416/" target="_self"><i className="fab fa-linkedin-in"></i></Link></li>
                      <li><Link to="https://www.instagram.com/toris.technologies/" target="_self"><i className="fab fa-instagram"></i></Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="octf-main-header">
          <div className="octf-area-wrap">
            <div className="container-fluid octf-mainbar-container">
              <div className="octf-mainbar">
                <div className="octf-mainbar-row octf-row">
                  <div className="octf-col logo-col">
                    <div id="site-logo" className="site-logo">
                      <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/toris-logo-bottom-text.png`} alt="TORIS Technologies" />
                      </Link>
                    </div>
                  </div>
                  <div className="octf-col menu-col">
                    <nav id="site-navigation" className="main-navigation">
                      <ul className="menu">
                        <li><Link to="/">Home</Link></li>
                        <li className="menu-item-has-children">Company
                          <ul className="sub-menu">
                            <li><Link to="/about">About us</Link></li>
                            <li><Link to="/why-choose-us">Why Choose Us</Link></li>
                            <li><Link to="/team">Our Team</Link></li>
                            <li><Link to="/faq">FAQS</Link></li>
                          </ul>
                        </li>
                        <li className="menu-item-has-children">Services
                          <ul className="sub-menu">
                            <li><Link to="/software-integrations">It Services</Link></li>
                            <li><Link to="/web-development">Web Development</Link></li>
                            <li><Link to="/mobile-development">Mobile Development</Link></li>
                          </ul>
                        </li>
                        <li><Link to="/portfolio">Projects</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/contact">Contacts</Link></li>
                        <li><Link to="/shop">Shop</Link></li>
                      </ul>
                    </nav>
                  </div>
                  <div className="octf-col cta-col text-right">
                    {/* Call To Action */}
                    <div className="octf-btn-cta">
                      <div className="octf-header-module cart-btn-hover">
                        <div className="h-cart-btn octf-cta-icons">
                          <Link className="cart-icon" to="/cart"><i className="flaticon-shopper"></i><span className="count">{cartItemCount}</span></Link>
                        </div>
                        <div className="site-header-cart">
                          <div className="widget woocommerce widget_shopping_cart">
                            <div className="widget_shopping_cart_content">
                              {cart.length === 0 ? (
                                <p className="woocommerce-mini-cart__empty-message">No products in the cart.</p>
                              ) : (
                                <ul className="cart_list">
                                  {cart.map(item => (
                                    <li key={item.id} className="clearfix">
                                      <button onClick={() => handleRemoveFromCart(item.id)} className="remove modern-remove-button">
                                        <i className="fa fa-trash"></i>
                                      </button>
                                      <div className="thumb">
                                        <Link to={item.link}><img src={item.image} alt={item.name} /></Link>
                                      </div>
                                      <div className="entry-header">
                                        <h6><Link to={item.link}>{item.name}</Link></h6>
                                        <span className="price-product"><span className="quantity">{item.quantity} × </span><span className="amount"><span>$</span>{item.price.toFixed(2)}</span></span>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="octf-header-module auth-icon-module">
                        <div className="auth-icon-container">
                          {auth.isAuthenticated ? (
                            <>
                              {auth.role === 'admin' || auth.role === 'moderator' || auth.role === 'guest blogger' ? (
                                <Link to="/admin" className="username-link">
                                  <MdDashboard   className="dashboard-icon" />
                                </Link>
                              ) : (
                                <span className="username">{auth.username}</span>
                              )}
                              <button onClick={logout} className="auth-button">
                                <FaSignOutAlt className="auth-icon" />
                              </button>
                            </>
                          ) : (
                            <>
                              <span className="login-text">Login</span>
                              <Link to="/login" className="auth-button">
                                <FaSignInAlt className="auth-icon" />
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="octf-header-module">
                        <div className="btn-cta-group contact-header">
                          <i className="fas fa-phone"></i>
                          <div className="cinfo-header">
                            <span>Have Any Questions?</span>
                            <span className="main-text"><Link to="tel:+27 81 297 6016">+27 81 297 6016</Link></span>
                          </div>
                        </div>
                      </div>
                      <div className="octf-header-module">
                        <div className="btn-cta-group btn-cta-header">
                          <Link className="octf-btn octf-btn-third" to="/contact">Free Quote</Link>
                        </div>
                      </div>
                    </div>                              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header_mobile">
          <div className="container">
            <div className="mlogo_wrapper clearfix">
              <div className="mobile_logo">
                <Link to="/">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/toris-logo-no-text.png`} alt="TORIS Technologies" />
                </Link>
              </div>
              <div id="mmenu_toggle">
                <button></button>
              </div>
            </div>
            <div className="mmenu_wrapper">
              <div className={`mobile_nav ${menuOpen ? 'open' : ''}`}>
                <ul id="menu-main-menu" className="mobile_mainmenu">
                  <li className="current-menu-item current-menu-ancestor">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="menu-item-has-children">Company
                    <ul className="sub-menu">
                      <li><Link to="/about">About us</Link></li>
                      <li><Link to="/why-choose-us">Why Choose Us</Link></li>
                      <li><Link to="/team">Team</Link></li>
                      <li><Link to="/faq">FAQS</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">Services
                    <ul className="sub-menu">
                      <li><Link to="/web-development">Web Development</Link></li>
                      <li><Link to="/mobile-development">Mobile Development</Link></li>
                      <li><Link to="/software-integrations">Software Integrations</Link></li>
                    </ul>
                  </li>
                  <li><Link to="/portfolio">Projects</Link></li>
                  <li><Link to="/blog">Blog</Link></li>
                  <li><Link to="/contact">Contacts</Link></li>
                  <li><Link to="/shop">Shop</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
