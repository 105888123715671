import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';

const Cart = () => {
  const { cart, dispatch } = useCart();

  const removeFromCart = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id } });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  };

  const updateQuantity = (id, quantity) => {
    dispatch({ type: 'UPDATE_QUANTITY', payload: { id, quantity } });
  };

  const cartTotal = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <div>
      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Your Cart</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li><Link to="/">Home</Link></li>
                <li className="active">Cart</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="shop-cart">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <table className="shop-cart-table shop_table_responsive">
                  <thead>
                    <tr>
                      <th className="product-image">Image</th>
                      <th className="product-name">Product</th>
                      <th className="product-price-title">Price</th>
                      <th className="product-quantity">Quantity</th>
                      <th className="product-subtotal">Subtotal</th>
                      <th className="product-remove">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map(item => (
                      <tr key={item.id} className="cart-item">
                        <td className="product-cart-image" data-title="Image">
                          <div className="cart-item-details">
                            <div className="thumb">
                              <Link to={item.link}><img src={item.image} alt={item.name} /></Link>
                            </div>
                          </div>
                        </td>
                        <td className="product-cart-name" data-title="Product">
                          <div className="entry-header">
                            <h6><Link to={item.link}>{item.name}</Link></h6>
                          </div>
                        </td>
                        <td className="product-price" data-title="Price">
                          <span className="amount">${item.price.toFixed(2)}</span>
                        </td>
                        <td className="product-quantity" data-title="Quantity">
                          <div className="quantity">
                            <input
                              type="number"
                              className="input-text qty text"
                              step="1"
                              min="1"
                              name="cart"
                              value={item.quantity}
                              onChange={(e) => updateQuantity(item.id, parseInt(e.target.value))}
                            />
                          </div>
                        </td>
                        <td className="product-subtotal" data-title="Subtotal">
                          <span className="amount">${(item.price * item.quantity).toFixed(2)}</span>
                        </td>
                        <td className="product-remove actions">
                          <button className="remove" onClick={() => removeFromCart(item.id)}>
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="actions text-right">
                        <button type="button" className="btn btn-primary" onClick={clearCart}>
                          Clear Cart
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="cart-collaterals">
                  <div className="cart-totals">
                    <h5>Cart Totals</h5>
                    <table className="shop_table">
                      <tbody>
                        <tr className="cart-subtotal">
                          <th>Subtotal</th>
                          <td data-title="Subtotal">
                            <span className="amount">${cartTotal.toFixed(2)}</span>
                          </td>
                        </tr>
                        <tr className="order-total">
                          <th>Total</th>
                          <td data-title="Total">
                            <strong><span className="amount">${cartTotal.toFixed(2)}</span></strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="proceed-to-checkout text-right">
                      <Link to="/checkout" className="btn btn-primary">
                        Proceed to Checkout
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Cart;
