import axios from 'axios';

// Fetch a single blog post by ID
export const fetchBlogPost = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching blog post:', error);
    throw error;
  }
};

export const fetchBlogs = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`);
    return response.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

// Fetch categories from the API
export const fetchCategories = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

// Like a blog post
export const likeBlogPost = async (id) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/blogs/${id}/like`);
    return response.data; // Return the updated blog post with new likes count
  } catch (error) {
    console.error('Error liking blog post:', error);
    throw error;
  }
};

// Toggle like/unlike for a blog post
export const toggleBlogLike = async (blogId, userId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/blogs/${blogId}/toggle-like`,
      { userId }
    );
    return response.data;
  } catch (error) {
    console.error('Error toggling blog like:', error);
    throw error;
  }
};

// Check if the user has already liked the post
export const checkIfUserLikedPost = async (blogId, userId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/blogs/${blogId}/is-liked/${userId}`
    );
    return response.data.liked;
  } catch (error) {
    console.error('Error checking if user liked post:', error);
    throw error;
  }
};
