import React from 'react';
import { Link } from 'react-router-dom';

const WebDevelopment = () => {
  return (
    <div>
        <div id="content" className="site-content">
            <div className="page-header flex-middle">
                <div className="container">
                    <div className="inner flex-middle">
                        <h1 className="page-title">Web Development</h1>
                        <ul id="breadcrumbs" className="breadcrumbs none-style">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/web-development">IT Services</Link></li>
                            <li className="active">Web Development</li>
                        </ul>    
                    </div>
                </div>
            </div>

            <section className="service-web">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center mb-5 mb-lg-0 align-self-center">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/pic1-service1.png`} alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="service-process">
                                <div className="ot-heading">
                                    <span>about service</span>
                                    <h2 className="main-heading">We Provide Best <br />Web Development</h2>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="process-box">
                                            <div className="step">01.</div>
                                            <h6 className="mb-0">Java Development</h6>
                                            <p>We’re committed to building sustainable and high-quality Java solutions.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="process-box">
                                            <div className="step">02.</div>
                                            <h6 className="mb-0">PHP Development</h6>
                                            <p>We’re committed to building sustainable and high-quality Java solutions.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="process-box">
                                            <div className="step">03.</div>
                                            <h6 className="mb-0">C++ Development</h6>
                                            <p>We’re committed to building sustainable and high-quality Java solutions.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="process-box">
                                            <div className="step">04.</div>
                                            <h6 className="mb-0">.NET Development</h6>
                                            <p>We’re committed to building sustainable and high-quality Java solutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                            <div className="service-box s-box">
                                <span className="big-number">01</span>
                                <div className="icon-main color-s1"><span className="flaticon-correct"></span></div>
                                <div className="content-box">
                                    <h5>Machine Learning</h5>
                                    <p>Support and Evolution</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                            <div className="service-box s-box">
                                <span className="big-number">02</span>
                                <div className="icon-main color-s2"><span className="flaticon-correct"></span></div>
                                <div className="content-box">
                                    <h5>Artificial Intelligence</h5>
                                    <p>Support and Evolution</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-box s-box">
                                <span className="big-number">03</span>
                                <div className="icon-main color-s3"><span className="flaticon-correct"></span></div>
                                <div className="content-box">
                                    <h5>Augmented Reality</h5>
                                    <p>Support and Evolution</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-web-video">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="tab-video">
                                <div className="ot-heading">
                                    <span>technology index</span>
                                    <h2 className="main-heading">We Organize Our <br />Production Process</h2>
                                </div>
                                <div className="space-15"></div>
                                <div className="ot-tabs">
                                    <ul className="tabs-heading unstyle">
                                        <li className="tab-link octf-btn current" data-tab="tab-1518">Analysis</li>
                                        <li className="tab-link octf-btn" data-tab="tab-2518">Design</li>
                                        <li className="tab-link octf-btn" data-tab="tab-3518">Testing</li>
                                    </ul>
                                    <div id="tab-1518" className="tab-content current">
                                        <p>Toris Technologies is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services. Our product design service lets you prototype, test and validate your ideas.</p>
                                        <p><Link className="btn-details" to="/native-development"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link></p>            
                                    </div>
                                    <div id="tab-2518" className="tab-content">
                                        <p>While integrating the security with the DevOps approach has always been of high demand, this requires a completely different range of processes and tools. DevOps is already known to boast of an agile development methodology. Now when the inputs of the security experts I’ll be brought in a collaborative environment, the project will have an array of effective security safeguards.</p>
                                        <p><Link className="btn-details" to="/web-development"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link></p>
                                    </div>
                                    <div id="tab-3518" className="tab-content">
                                        <p>In spite of disturbing the agile development process of the DevOps approach, the additional security inputs only enhance the safeguards and reliability of the project. So, in a way, DevSecOps will only extend the benefits of a DevOps approach further with security inputs. DevOps is already known to boast of an agile development methodology.</p>
                                        <p><Link className="btn-details" to="/mobile-development"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="offset-xl-1 col-xl-6 col-lg-6 split-right-img">
                            <div className="video-popup">
                                <div className="btn-inner">
                                    <Link className="btn-play" to="https://vimeo.com/94686522">
                                        <i className="flaticon-play"></i>
                                        <span className="circle-1"></span>
                                        <span className="circle-2"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-web-pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="ot-heading">
                                <span>choose your plan</span>
                                <h2 className="main-heading">Flexible Pricing Plans</h2>
                            </div>
                            <div className="space-5"></div>
                            <p>We help businesses elevate their value through custom software development, product design, QA and consultancy services.</p>
                            <div className="space-20"></div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                            <div className="ot-pricing-table">
                            <div className="icon-main"><span className="flaticon-tablet"></span></div>
                                <div className="inner-table">
                                    <h4 className="title-table">Basic Plan</h4>
                                    <h2><sup>$</sup> 129.99</h2>
                                    <span>Monthly Package</span>
                                    <div className="details ">
                                        <ul>
                                            <li className="active">Web Counsulting</li>
                                            <li className="active">24/7 System Monitoring</li>
                                            <li className="active">Machine and Deep Learning</li>
                                            <li>Data Quality Management</li>
                                            <li>Security Management</li>
                                        </ul>
                                    </div>
                                    <Link to="#" className="octf-btn">Choose Plane</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                            <div className="ot-pricing-table">
                            <div className="icon-main"><span className="flaticon-report"></span></div>
                                <div className="inner-table">
                                    <h4 className="title-table">Economy Plan</h4>
                                    <h2><sup>$</sup> 159.99</h2>
                                    <span>Monthly Package</span>
                                    <div className="details ">
                                        <ul>
                                            <li className="active">Web Counsulting</li>
                                            <li className="active">24/7 System Monitoring</li>
                                            <li className="active">Machine and Deep Learning</li>
                                            <li className="active">Data Quality Management</li>
                                            <li>Security Management</li>
                                        </ul>
                                    </div>
                                    <Link to="#" className="octf-btn">Choose Plane</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="ot-pricing-table">
                            <div className="icon-main"><span className="flaticon-ui"></span></div>
                                <div className="inner-table">
                                    <h4 className="title-table">Premium Plan</h4>
                                    <h2><sup>$</sup> 189.99</h2>
                                    <span>Monthly Package</span>
                                    <div className="details ">
                                        <ul>
                                        <li className="active">Web Counsulting</li>
                                        <li className="active">24/7 System Monitoring</li>
                                        <li className="active">Machine and Deep Learning</li>
                                        <li className="active">Data Quality Management</li>
                                        <li className="active">Security Management</li>
                                        </ul>
                                    </div>
                                    <Link to="#" className="octf-btn">Choose Plane</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="service-partner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="partners">
                                <div className="owl-carousel owl-theme home-client-carousel">
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client1.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client2.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client3.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client4.png`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client5.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client6.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client1.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client2.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client3.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client4.png`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client5.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                    <div className="partners-slide">
                                        <Link to="#" className="client-logo">
                                            <figure className="partners-slide-inner">
                                                <img className="partners-slide-image" src={`${process.env.PUBLIC_URL}/assets/images/client-logos/client6.svg`} alt="" />
                                            </figure>                             
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="service-web-clients"> 
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ot-heading text-center">
                                <span>our clients</span>
                                <h2 className="main-heading">We are Trusted <br />15+ Countries Worldwide</h2>
                            </div>
                        </div>
                    </div>
                    <div className="space-35"></div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ot-testimonials">
                                <div className="owl-carousel owl-theme testimonial-inner ot-testimonials-slider">
                                    <div className="testi-item">
                                        <div className="layer1"></div>
                                        <div className="layer2">
                                            <div className="t-head flex-middle">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/testi2.png`} alt="" />
                                                    <div className="tinfo">
                                                        <h6>SoftTech,</h6>
                                                        <span>Manager of Company</span>                             
                                                    </div>
                                            </div>
                                            <div className="ttext">
                                                "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testi-item">
                                        <div className="layer1"></div>
                                        <div className="layer2">
                                            <div className="t-head flex-middle">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/testi1.png`} alt="" />
                                                    <div className="tinfo">
                                                        <h6>Moonkle LTD,</h6>
                                                        <span>Client of Company</span>                             
                                                    </div>
                                            </div>
                                            <div className="ttext">
                                                "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testi-item">
                                        <div className="layer1"></div>
                                        <div className="layer2">
                                            <div className="t-head flex-middle">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/testi2.png`} alt="" />
                                                    <div className="tinfo">
                                                        <h6>SoftTech,</h6>
                                                        <span>Manager of Company</span>                             
                                                    </div>
                                            </div>
                                            <div className="ttext">
                                                "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testi-item">
                                        <div className="layer1"></div>
                                        <div className="layer2">
                                            <div className="t-head flex-middle">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/testi1.png`} alt="" />
                                                    <div className="tinfo">
                                                        <h6>Moonkle LTD,</h6>
                                                        <span>Client of Company</span>                             
                                                    </div>
                                            </div>
                                            <div className="ttext">
                                                "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
  )
}

export default WebDevelopment