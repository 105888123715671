import React from 'react';
import { Link } from 'react-router-dom';

const SingleProduct = () => {
  return (
    <div>
      <div id="content" className="site-content">
            <div className="page-header flex-middle">
                <div className="container">
                    <div className="inner flex-middle">
                        <h1 className="page-title">Single Product</h1>
                        <ul id="breadcrumbs" className="breadcrumbs none-style">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/shop">Shop</Link></li>
                            <li className="active">Single Product</li>
                        </ul>    
                    </div>
                </div>
            </div>
            <section className="shop-single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-4 mb-lg-0 text-center align-self-center">
                            <div className="product-slide">
                                <div className="zoom"></div>
                                <div className="single-product owl-carousel owl-theme">
                                    <div className="item" data-hash="zero">
                                        <Link to="images/product/single-product-1.jpg" className="link-image-action"><i className="flaticon-search"></i></Link>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/product/single-product-1.jpg`} alt="" data-zoom="images/product/single-product-1.jpg" />
                                    </div>
                                    <div className="item" data-hash="one">
                                        <Link to="images/product/single-product-2.jpg" className="link-image-action"><i className="flaticon-search"></i></Link>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/product/single-product-2.jpg`} alt="" data-zoom="images/product/single-product-2.jpg" />
                                    </div>
                                    <div className="item" data-hash="two">
                                        <Link to="images/product/single-product-3.jpg" className="link-image-action"><i className="flaticon-search"></i></Link>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/product/single-product-3.jpg`} alt="" data-zoom="images/product/single-product-3.jpg" />
                                    </div>
                                    <div className="item" data-hash="three">
                                        <Link to="images/product/single-product-4.jpg" className="link-image-action"><i className="flaticon-search"></i></Link>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/product/single-product-4.jpg`} alt="" data-zoom="images/product/single-product-4.jpg" />
                                    </div>
                                </div>
                                <div className="nav-img">
                                    <div className="item">
                                        <div>
                                            <Link className="" to="#zero">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/product/single-product-1.jpg`} alt="" />
                                            </Link> 
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div>
                                            <Link className="" to="#one">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/product/single-product-2.jpg`} alt="" />
                                            </Link> 
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div>
                                            <Link className="" to="#two">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/product/single-product-3.jpg`} alt="" />
                                            </Link> 
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div>
                                            <Link className="" to="#three">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/product/single-product-4.jpg`} alt="" />
                                            </Link> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="summary entry-summary">
                                <h2 className="single-product-title">T-Shirt with Logo</h2>
                                <div className="single-product-rating">
                                    <div className="star-rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <Link to="#reviews" className="woocommerce-review-link" rel="nofollow">(<span className="count">1</span> customer review)</Link>
                                    </div>
                                </div>
                                <span className="single-price price-product"><span className="amount"><span>$</span>29.99</span></span>
                                <p>In today’s online world, a brand’s success lies in combining technological planning and social strategies to draw customers in–and keep them coming back for more. Without a strong digital platform, time and money are wasted, content sits unnoticed, and prospective clients disappear.</p>
                                <p>Fortunately, two seasoned digital marketers have a plan to make your brand succeed. In Faster, Smarter, Louder, Aaron Agius and Gián Clancey share the secrets that transformed their two-person agency.</p>
                                <form className="cart" method="post">
                                    <div className="single-quantity">
                                        <label className="screen-reader-text">Boost Your Business quantity</label>
                                        <input type="number" id="quantity" className="input-text qty text" step="1" min="1" name="quantity" value="1" title="Qty" placeholder="" />
                                    </div>
                                    <button type="submit" name="add-to-cart" className="octf-btn single_add_to_cart_button button alt">Add to cart</button>
                                </form>
                                <div className="product_meta">
                                    <span className="sku_wrapper">SKU: <span className="sku">124224</span></span>
                                    <span className="posted_in">Categories: <Link to="#">Case &amp; Bags</Link>, <Link to="#">Clothes</Link></span>
                                    <span className="tagged_as">Tags: <Link to="#">accessories</Link>, <Link to="#">business</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-70"></div>
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <div className="ot-tabs">
                                <ul className="tabs-heading unstyle">
                                    <li className="tab-link octf-btn current" data-tab="tab-1">Description</li>
                                    <li className="tab-link octf-btn" data-tab="tab-2">Infomation</li>
                                    <li className="tab-link octf-btn" data-tab="tab-3">Review <span>(01)</span></li>
                                </ul>
                                <div id="tab-1" className="tab-content current">
                                    <p>While integrating the security with the DevOps approach has always been of high demand, this requires a completely different range of processes and tools. DevOps is already known to boast of an agile development methodology. Now when the inputs of the security experts I’ll be brought in a collaborative environment, the project will have an array of effective security safeguards.</p>
                                    <p>In spite of disturbing the agile development process of the DevOps approach, the additional security inputs only enhance the safeguards and reliability of the project. So, in a way, DevSecOps will only extend the benefits of a DevOps approach further with security inputs.</p>
                                </div>
                                <div id="tab-2" className="tab-content">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <table className="woocommerce-product-attributes shop_attributes">
                                                <tbody>
                                                    <tr>
                                                        <th>Fabric</th>
                                                        <td><p>Jacquard</p></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Color</th>
                                                        <td><p>Black, Red, Blue</p></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Weight</th>
                                                        <td><p>20oz / 0.57kg</p></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Height</th>
                                                        <td><p>13.75in / 35cm</p></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Length</th>
                                                        <td><p>9in / 23cm</p></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Depth</th>
                                                        <td><p>6in / 15cm</p></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Size</th>
                                                        <td><p>S, M, XL</p></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Other Info</th>
                                                        <td><p>Duis ullamcorper arcu et ligula volutpat imperdiet</p></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-3" className="tab-content">
                                    <h2 className="woocommerce-reviews-title">1 review for <span>T-Shirt with Logo</span></h2>
                                    <div className="review-comment">
                                        <ol className="review-comment-list">
                                            <li className="comment-item">
                                                <div className="comment-wrap">
                                                    <div className="avatar-author-review">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/author-review.jpg`} alt="" className="review-avatar" />
                                                    </div>
                                                    <div className="review-rating">
                                                        <span><i className="fa fa-star"></i></span>
                                                        <span><i className="fa fa-star"></i></span>
                                                        <span><i className="fa fa-star"></i></span>
                                                        <span><i className="fa fa-star"></i></span>
                                                        <span><i className="fa fa-star"></i></span>
                                                    </div>
                                                    <div className="review-comment-content">
                                                        <div className="comment-meta">
                                                            <h6 className="comment-author">Tom Black </h6>
                                                            <span className="comment-time"> - March 13, 2020</span>
                                                        </div>
                                                        <div className="comment-text">
                                                            <p>Nice product !</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="review-form">
                                        <span className="comment-reply-title">Add a review </span>
                                        <form className="review-comment-form">
                                            <p className="comment-notes"><span id="email-notes">Your email address will not be published.</span> Required fields are marked <span className="required">*</span></p>
                                            <div className="comment-form-rating">
                                                <label>Your rating <span className="required">*</span></label>
                                                <div className="review-comment-rating mb-3">
                                                    <span><i className="far fa-star"></i></span>
                                                    <span><i className="far fa-star"></i></span>
                                                    <span><i className="far fa-star"></i></span>
                                                    <span><i className="far fa-star"></i></span>
                                                    <span><i className="far fa-star"></i></span>
                                                </div>
                                            </div>
                                            <p className="comment-form-author">
                                                <input id="author" name="author" type="text" value="" size="30" placeholder="Name *" required="" />
                                            </p>
                                            <p className="comment-form-email">
                                                <input id="email" name="email" type="text" value="" size="30" placeholder="Email *" required="" />
                                            </p>
                                            <p className="comment-form-comment">
                                                <textarea id="comment" name="comment" cols="45" rows="8" placeholder="Comment *" required=""></textarea>
                                            </p>
                                            <p className="form-submit">
                                                <input name="submit" type="submit" id="submit" className="octf-btn" value="Submit" /> 
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center mb-2">
                            <h2>Related Products</h2>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="product-item">
                                <div className="product-media"> 
                                    <Link to="/single-product">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/product/product-4.jpg`} className="" alt="" />
                                    </Link>
                                    <div className="wrapper-add-to-cart">
                                        <div className="add-to-cart-inner">
                                            <Link to="/cart" className="octf-btn octf-btn-primary">Add to cart </Link>           
                                        </div>
                                    </div>
                                </div>
                                <h2 className="woocommerce-loop-product__title"><Link to="/single=product">Your Energy Drink</Link></h2>
                                <span className="price-product"><span className="amount"><span>$</span>39.99</span></span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="product-item">
                                <div className="product-media"> 
                                    <Link to="/single-product">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/product/product-5.jpg`} className="" alt="" />
                                    </Link>
                                    <div className="wrapper-add-to-cart">
                                        <div className="add-to-cart-inner">
                                            <Link to="/cart" className="octf-btn octf-btn-primary">Add to cart </Link>           
                                        </div>
                                    </div>
                                </div>
                                <h2 className="woocommerce-loop-product__title"><Link to="/single=product">T-Shirt with Logo</Link></h2>
                                <span className="price-product"><span className="amount"><span>$</span>59.99</span></span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="product-item">
                                <div className="product-media"> 
                                    <Link to="/single-product">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/product/product-3.jpg`} className="" alt="" />
                                    </Link>
                                    <div className="wrapper-add-to-cart">
                                        <div className="add-to-cart-inner">
                                            <Link to="/cart" className="octf-btn octf-btn-primary">Add to cart </Link>           
                                        </div>
                                    </div>
                                </div>
                                <h2 className="woocommerce-loop-product__title"><Link to="/single=product">Hoodie with Logo</Link></h2>
                                <span className="price-product"><span className="amount"><span className="">$</span>39.99</span></span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="product-item">
                                <div className="product-media"> 
                                    <Link to="/single-product">
                                        <span className="onsale">Sale!</span>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/product/product-6.jpg`} className="" alt="" />
                                    </Link>
                                    <div className="wrapper-add-to-cart">
                                        <div className="add-to-cart-inner">
                                            <Link to="/cart" className="octf-btn octf-btn-primary">Add to cart </Link>           
                                        </div>
                                    </div>
                                </div>
                                <h2 className="woocommerce-loop-product__title"><Link to="single=/product">Case for IPhone 11 Pro</Link></h2>
                                <span className="price-product">
                                    <del><span className="amount"><span>$</span>59.99</span></del> 
                                    <ins><span className="amount"><span>$</span>29.99</span></ins>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  )
}

export default SingleProduct