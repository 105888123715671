import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { sendContactForm } from "../utils/apiServices";

const Team = () => {
  // State to manage form inputs
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    site: "",
    message: "",
  });

  // State to manage submission status
  const [formStatus, setFormStatus] = useState("");

  // State to manage the reCAPTCHA token
  const [captchaToken, setCaptchaToken] = useState(null);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle reCAPTCHA change
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Store the reCAPTCHA token
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setFormStatus("Please complete the reCAPTCHA.");
      return;
    }

    // Combine the website field with the message field
    const combinedMessage = `
      <strong>Website:</strong> ${
        formValues.site ? formValues.site : "Not provided"
      }
      <br/>
      <strong>Message:</strong> ${formValues.message}
    `;
    console.log("captchaToken:", captchaToken); // Log token before submitting

    try {
      // Send the form data with the combined message and reCAPTCHA token
      await sendContactForm({
        name: formValues.name,
        email: formValues.email,
        message: combinedMessage,
        captchaToken, // Include the reCAPTCHA token
      });

      setFormStatus("Message sent successfully!");
      setCaptchaToken(null); // Reset the captcha after success
    } catch (error) {
      setFormStatus(
        "There was an error sending your message. Please try again."
      );
    }
  };

  return (
    <div>
      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Team</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/team">Our Team</Link>
                </li>
                <li className="active">Team</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="team-about sm-space">
          <div className="container">
            <div className="steam-info">
              <div className="row">
                <div className="col-lg-6">
                  <div className="team-info-left">
                    <div className="team-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/johann_coetzee_profile_picture.png`}
                        alt="Johann Coetzee"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="team-detail">
                    <h2>Johann Coetzee</h2>
                    <span className="location">Technical Director</span>
                    <ul className="bold member-info">
                      <li>
                        <span className="text-dark">Department:</span>{" "}
                        Development
                      </li>
                      <li>
                        <span className="text-dark">Experience:</span> 15 Years
                      </li>
                      <li>
                        <span className="text-dark">Email:</span>{" "}
                        johann@toris.co.za
                      </li>
                      <li>
                        <span className="text-dark">Phone:</span> +27 81 297
                        6016
                      </li>
                    </ul>
                    <div className="otf-social-share clearfix shape-circle">
                      <Link
                        className="share-facebook"
                        to="https://www.facebook.com/johann.coetzee.92/"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                      <Link
                        className="share-instagram"
                        to="https://www.instagram.com/johanngcoetzee/"
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </Link>
                      <Link
                        className="share-linkedin"
                        to="https://www.linkedin.com/in/johann-coetzee-90a297145/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="team-slills">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3>Biography</h3>
                <p>
                  At 33, with over 14 years of involvement in the Internet of
                  Things (IoT) sector, I am a seasoned innovator in software
                  development and digital strategy. My career is distinguished
                  not only by the development of diverse applications—ranging
                  from native to web-based solutions—but also by my expertise in
                  seamlessly integrating third-party systems to enhance business
                  operations. Beyond technical proficiency, my passion lies in
                  mentoring; I have guided hundreds of developers, nurturing
                  their growth across various tech stacks. My approach combines
                  a deep understanding of market needs with a keen ability to
                  tailor solutions that align precisely with business
                  objectives. Characterized by a robust blend of strategic
                  insight and technical acumen, I am committed to fostering
                  technological advancements that improve user experiences and
                  drive industry standards forward.
                </p>
              </div>
            </div>
            <div className="space-25"></div>
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 text-center text-md-left">
                <div className="team-profile">
                  <h6>Business Analysis</h6>
                  <p>Sales, Processes</p>
                </div>
                <div className="team-profile">
                  <h6>Custom Development</h6>
                  <p>Web Apps, Mobile Apps</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 text-center text-md-left">
                <div className="team-profile">
                  <h6>Digital Marketing</h6>
                  <p>Paid Advertising, Social Media</p>
                </div>
                <div className="team-profile">
                  <h6>Content Creation</h6>
                  <p>Social Media</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 mb-4">
                <div
                  className="circle-progress flex-middle"
                  data-color="#43BAFF"
                  data-height="10"
                  data-size="135"
                >
                  <div className="inner-bar" data-percent="50">
                    <span>
                      <span className="percent">0</span>%
                    </span>
                  </div>
                  <h4>
                    Business <br />
                    Analysis
                  </h4>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 mb-4">
                <div
                  className="circle-progress flex-middle"
                  data-color="#43BAFF"
                  data-height="10"
                  data-size="135"
                >
                  <div className="inner-bar" data-percent="50">
                    <span>
                      <span className="percent">0</span>%
                    </span>
                  </div>
                  <h4>
                    Application <br />
                    Development
                  </h4>
                </div>
              </div>
            </div>
            <div className="space-25"></div>
            <div className="row">
              <div className="col-lg-12">
                <h3>Hard Skills</h3>
                <p>
                  In the competitive realm of app development, an application is
                  remembered not only for its robust functionalities but also
                  for the seamless support it offers its users. Drawing on over
                  14 years of experience in IoT and software development, I have
                  consistently prioritized integrating a
                  <em>
                    <strong> helpful customer support system</strong>
                  </em>
                  into the apps I develop. This commitment mirrors the essence
                  of great brands globally, which are celebrated not just for
                  their products but also for their exceptional user support and
                  services. My approach ensures that the applications I create
                  not only meet technical expectations but also enhance user
                  satisfaction and engagement.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="team-contact">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <form
                  onSubmit={handleSubmit} // Add submit handler
                  className="wpcf7-form init"
                >
                  <div className="member-form comment-respond">
                    <h2>Contact Me</h2>
                    <p className="font14">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <div className="row">
                      <p className="col-md-6">
                        <input
                          type="text"
                          name="name"
                          value={formValues.name}
                          onChange={handleInputChange}
                          size="40"
                          aria-required="true"
                          placeholder="Your Name *"
                          required
                        />
                      </p>

                      <p className="col-md-6">
                        <input
                          type="email"
                          name="email"
                          value={formValues.email}
                          onChange={handleInputChange}
                          size="40"
                          aria-required="true"
                          placeholder="Your Email *"
                          required
                        />
                      </p>
                    </div>
                    <p>
                      <input
                        type="text"
                        name="site"
                        value={formValues.site}
                        onChange={handleInputChange}
                        size="40"
                        placeholder="Website"
                      />
                    </p>
                    <p>
                      <textarea
                        name="message"
                        value={formValues.message}
                        onChange={handleInputChange}
                        cols="40"
                        rows="10"
                        placeholder="Message..."
                        required
                      ></textarea>
                    </p>

                    {/* Google reCAPTCHA */}
                    <ReCAPTCHA
                      sitekey="6LcvFEAqAAAAADhYksuiu-WC50qNV6Tz32LvkWxm"
                      onChange={handleCaptchaChange}
                    />

                    <p>
                      <button
                        type="submit"
                        className="octf-btn octf-btn-primary"
                      >
                        Send Message
                      </button>
                    </p>
                    {formStatus && <p>{formStatus}</p>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Team;
