// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    token: null,
    userId: null,
    role: null,
    username: null,
  });

  // Restore the auth state from sessionStorage when the app loads
  useEffect(() => {
    const storedToken = sessionStorage.getItem('token');
    const storedUserId = sessionStorage.getItem('userId');
    const storedRole = sessionStorage.getItem('role');
    const storedUsername = sessionStorage.getItem('username');
    
    if (storedToken && storedUserId && storedRole && storedUsername) {
      setAuth({
        isAuthenticated: true,
        token: storedToken,
        userId: storedUserId,
        role: storedRole,
        userName: storedUsername,
      });
    }
  }, []);

  // Login method: store token, userId, and role in sessionStorage
  const login = (token, userId, role, username) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('userId', userId);
    sessionStorage.setItem('role', role);
    sessionStorage.setItem('username', username);

    setAuth({
      isAuthenticated: true,
      token,
      userId,
      role,
      username,
    });
  };

  // Logout method: clear sessionStorage
  const logout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('username');

    setAuth({
      isAuthenticated: false,
      token: null,
      userId: null,
      role: null,
      username: null,
    });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
