import React from "react";
import { Link } from "react-router-dom";

const SoftwareIntegrations = () => {
  return (
    <div>
      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">IT Services</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">IT Services</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="service-page">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-8 mb-4 mb-sm-0">
                <div className="ot-heading mb-0">
                  <span>our service</span>
                  <h2 className="main-heading">
                    We Offer a Wide <br />
                    Variety of IT Services
                  </h2>
                </div>
              </div>
              <div className="col-md-6 col-sm-4 text-sm-right align-self-end">
                <div className="ot-button">
                  <Link to="/" className="octf-btn octf-btn-primary">
                    all sevices
                  </Link>
                </div>
                <div className="space-10"></div>
              </div>
            </div>
            <div className="space-55"></div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 pb-60">
                  <div className="icon-main">
                    <span className="flaticon-code"></span>
                  </div>
                  <div className="content-box">
                    <h5>Web Development</h5>
                    <p>
                      We carry more than just good coding skills. Our experience
                      makes us stand out from other web development.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 pb-60">
                  <div className="icon-main">
                    <span className="flaticon-app"></span>
                  </div>
                  <div className="content-box">
                    <h5>Mobile Development</h5>
                    <p>
                      Create complex enterprise software, ensure reliable
                      software integration, modernise your legacy system.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 pb-60">
                  <div className="icon-main">
                    <span className="flaticon-monitor"></span>
                  </div>
                  <div className="content-box">
                    <h5>UI/UX Design</h5>
                    <p>
                      Build the product you need on time with an experienced
                      team that uses a clear and effective design process.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 sm-pb-60">
                  <div className="icon-main">
                    <span className="flaticon-browser"></span>
                  </div>
                  <div className="content-box">
                    <h5>QA &amp; Testing</h5>
                    <p>
                      Turn to our experts to perform comprehensive, multi-stage
                      testing and auditing of your software.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1 xs-pb-60">
                  <div className="icon-main">
                    <span className="flaticon-report-1"></span>
                  </div>
                  <div className="content-box">
                    <h5>IT Counsultancy</h5>
                    <p>
                      Trust our top minds to eliminate workflow pain points,
                      implement new tech, and consolidate app portfolios.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box-s2 s1">
                  <div className="icon-main">
                    <span className="flaticon-best"></span>
                  </div>
                  <div className="content-box">
                    <h5>Dedicated Team</h5>
                    <p>
                      Over the past decade, our customers succeeded by
                      leveraging Intellectsoft’s process of building,
                      motivating.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="service-pricing">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="ot-heading">
                  <span>choose your plan</span>
                  <h2 className="main-heading">Flexible Pricing Plans</h2>
                </div>
                <div className="space-5"></div>
                <p>
                  We help businesses elevate their value through custom software
                  development,
                  <br />
                  product design, QA and consultancy services.
                </p>
                <div className="space-20"></div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div className="ot-pricing-table">
                  <div className="icon-main">
                    <span className="flaticon-tablet"></span>
                  </div>
                  <div className="inner-table">
                    <h4 className="title-table">Basic Plan</h4>
                    <h2>
                      <sup>$</sup> 129.99
                    </h2>
                    <span>Monthly Package</span>
                    <div className="details ">
                      <ul>
                        <li className="active">Web Counsulting</li>
                        <li className="active">24/7 System Monitoring</li>
                        <li className="active">Machine and Deep Learning</li>
                        <li>Data Quality Management</li>
                        <li>Security Management</li>
                      </ul>
                    </div>
                    <Link to="#" className="octf-btn">
                      Choose Plane
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div className="ot-pricing-table">
                  <div className="icon-main">
                    <span className="flaticon-report"></span>
                  </div>
                  <div className="inner-table">
                    <h4 className="title-table">Economy Plan</h4>
                    <h2>
                      <sup>$</sup> 159.99
                    </h2>
                    <span>Monthly Package</span>
                    <div className="details ">
                      <ul>
                        <li className="active">Web Counsulting</li>
                        <li className="active">24/7 System Monitoring</li>
                        <li className="active">Machine and Deep Learning</li>
                        <li className="active">Data Quality Management</li>
                        <li>Security Management</li>
                      </ul>
                    </div>
                    <Link to="#" className="octf-btn">
                      Choose Plane
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="ot-pricing-table">
                  <div className="icon-main">
                    <span className="flaticon-ui"></span>
                  </div>
                  <div className="inner-table">
                    <h4 className="title-table">Premium Plan</h4>
                    <h2>
                      <sup>$</sup> 189.99
                    </h2>
                    <span>Monthly Package</span>
                    <div className="details ">
                      <ul>
                        <li className="active">Web Counsulting</li>
                        <li className="active">24/7 System Monitoring</li>
                        <li className="active">Machine and Deep Learning</li>
                        <li className="active">Data Quality Management</li>
                        <li className="active">Security Management</li>
                      </ul>
                    </div>
                    <Link to="#" className="octf-btn">
                      Choose Plane
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-consultation">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>Drop us a line! We are here to answer your questions 24/7</p>
                <h2>NEED A CONSULTATION?</h2>
                <div className="ot-button">
                  <Link to="/contact" className="octf-btn octf-btn-primary">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-clients bg-map-dots">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="ot-heading text-center">
                  <span>our clients</span>
                  <h2 className="main-heading">
                    We are Trusted <br />
                    15+ Countries Worldwide
                  </h2>
                </div>
              </div>
            </div>
            <div className="space-35"></div>
            <div className="row">
              <div className="col-md-12">
                <div className="ot-testimonials">
                  <div className="owl-carousel owl-theme testimonial-inner ot-testimonials-slider">
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi2.png`}
                            alt="Emilia Clarke"
                            className="lazyloaded"
                            data-ll-status="loaded"
                          />
                          <div className="tinfo">
                            <h6>SoftTech,</h6>
                            <span>Manager of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Patience. Infinite patience. No shortcuts. Very well
                          thought out and articulate communication. Clear
                          milestones, deadlines and fast work. Even if the
                          client is being careless. The best part...always
                          solving problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi1.png`}
                            alt="Emilia Clarke"
                            className="lazyloaded"
                            data-ll-status="loaded"
                          />
                          <div className="tinfo">
                            <h6>Moonkle LTD,</h6>
                            <span>Client of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Very well thought out and articulate communication.
                          Clear milestones, deadlines and fast work. Patience.
                          Infinite patience. No shortcuts. Even if the client is
                          being careless. The best part...always solving
                          problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi2.png`}
                            alt="Emilia Clarke"
                            className="lazyloaded"
                            data-ll-status="loaded"
                          />
                          <div className="tinfo">
                            <h6>SoftTech,</h6>
                            <span>Manager of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Patience. Infinite patience. No shortcuts. Very well
                          thought out and articulate communication. Clear
                          milestones, deadlines and fast work. Even if the
                          client is being careless. The best part...always
                          solving problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/testi1.png`}
                            alt="Emilia Clarke"
                            className="lazyloaded"
                            data-ll-status="loaded"
                          />
                          <div className="tinfo">
                            <h6>Moonkle LTD,</h6>
                            <span>Client of Company</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Very well thought out and articulate communication.
                          Clear milestones, deadlines and fast work. Patience.
                          Infinite patience. No shortcuts. Even if the client is
                          being careless. The best part...always solving
                          problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SoftwareIntegrations;
