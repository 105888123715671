import React from 'react';
import { Link } from 'react-router-dom';
import './ProductCard.css';
import { useCart } from '../../context/CartContext';

const ProductCard = ({ product }) => {
  const { dispatch } = useCart();

  const addToCart = () => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  return (
    <div className="product-item modern-card">
      <div className="product-media">
        <Link to={product.link}>
          {product.sale && <span className="onsale">Sale!</span>}
          <img src={product.image} alt={product.name} className="product-image" />
        </Link>
      </div>
      <h2 className="woocommerce-loop-product__title">
        <Link to={product.link} className="product-title">{product.name}</Link>
      </h2>
      <div className="star-rating">
        {Array(product.rating).fill().map((_, i) => (
          <span key={i}><i className="fa fa-star"></i></span>
        ))}
      </div>
      <span className="price-product">
        {product.sale ? (
          <>
            <del><span className="amount"><span>$</span>{product.oldPrice}</span></del>
            <ins><span className="amount"><span>$</span>{product.price}</span></ins>
          </>
        ) : (
          <ins><span className="amount"><span>$</span>{product.oldPrice}</span></ins>
        )}
      </span>
      <div className="wrapper-add-to-cart">
        <button onClick={addToCart} className="octf-btn octf-btn-primary">Add to Cart</button>
      </div>
    </div>
  );
};

export default ProductCard;
