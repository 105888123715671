import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; // Make sure your AuthContext is ready to store token and userId
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/users/login`, {
        email,
        password,
      });
  
      // Assuming the response contains token, userId, and role
      const { token, userId, role } = response.data;
  
      // Store the user data in context
      login(token, userId, role); 
  
      // Navigate based on role
      if (role === 'admin' || role === 'moderator' || role === 'guest blogger') {
        navigate('/admin'); // Navigate to admin dashboard
      } else {
        navigate('/shop'); // Navigate to shop for normal users
      }
  
    } catch (error) {
      console.error('Error logging in user:', error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
  };
  

  return (
    <div className="login-container">
      <h2 className="login-title">Login</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
        <div className='register-footer-text'>
          <p>Don't have an account? </p><Link to="/register">Register now!</Link>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Login;
