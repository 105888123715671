import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div id="content" className="site-content">
    <div className="page-header flex-middle">
        <div className="container">
            <div className="inner flex-middle">
                <h1 className="page-title">About Us</h1>
                <ul id="breadcrumbs" className="breadcrumbs none-style">
                    <li><Link to="/">Home</Link></li>
                    <li className="active">About Us</li>
                </ul>    
            </div>
        </div>
    </div>
    <section className="about-offer">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 align-self-center mb-30 mb-lg-0">
                    <div className="ot-heading">
                        <span>what we offer</span>
                        <h2 className="main-heading">Your Partner for <br /> Software Innovation</h2>
                    </div>
                    <div className="space-5"></div>
                    <p>Toris Technologies is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services.</p>
                    <p><em className="text-dark"><strong>We can help to maintain and modernize your IT infrastructure and solve various infrastructure-specific issues a business may face.</strong></em></p>
                    <div className="space-20"></div>
                    <div className="video-popup style-2">
                        <div className="btn-inner">
                            <Link className="btn-play" to="https://www.youtube.com/watch?v=lfDZJqSrIuk">
                                <i className="flaticon-play"></i>
                                <span className="circle-1"></span>
                                <span className="circle-2"></span>
                            </Link>
                        </div>
                        <span>video showcase</span>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <Link className="ot-image-box v3 st1" to="#">
                                <div className="overlay">
                                    <h4>Our Mission</h4>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/image-box1.jpg`} alt="Our Mission" />
                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <Link className="ot-image-box v3 st2" to="#">
                                <div className="overlay">
                                    <h4>Our Vision</h4>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/image-box2.jpg`} alt="Our Vision" />
                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <Link className="ot-image-box v3 st3" to="#">
                                <div className="overlay">
                                    <h4>Our Philosophy</h4>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/image-box3.jpg`} alt="Our Philosophy" />
                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <Link className="ot-image-box v3 st4 mb-0" to="#">
                                <div className="overlay">
                                    <h4>Our Strategy</h4>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/image-box4.jpg`} alt="Our Strategy" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="about-v5">
        <div className="overlay overlay-image-about5"></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-6 align-self-center">
                    <div className="pabout-left">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/image1-about.png`} alt="" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="pabout-right">
                        <div className="ot-heading">
                            <span>Experience. Execution. Excellence.</span>
                            <h2 className="main-heading">What We Actually Do</h2>
                        </div>
                        <div className="tech-wrap">
                            <div className="tech-box" data-tech="web">
                                <div className="icon-main"><span className="flaticon-php"></span></div>
                            </div>
                            <div className="tech-box" data-tech="mobile">
                                <div className="icon-main"><span className="flaticon-electron"></span></div>
                            </div>
                            <div className="tech-box" data-tech="native">
                                <div className="icon-main"><span className="flaticon-java"></span></div>
                            </div>
                            <div className="tech-box" data-tech="integration">
                                <div className="icon-main"><span className="flaticon-css"></span></div>
                            </div>
                        </div>
                        <h5>Web Application Development</h5>
                        <p>We build robust, scalable web applications tailored to your business needs, using cutting-edge technologies to ensure optimal performance and user engagement.</p>
                        <div className="ot-button">
                            <Link to="/web-development" className="btn-details"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link>
                        </div>
                    </div>
                </div>                               
            </div>
        </div>
    </section>
  
    <section className="about-counter">
        <div className="container">
           
            <div className="space-105"></div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="technology-v3-left">
                        <div className="ot-heading">
                            <span>technology index</span>
                            <h2 className="main-heading">Improve and Innovate with the Tech Trends</h2>
                        </div>
                        <div className="space-5"></div>
                        <p>Your development team is catered exactly for your requirements.</p>
                        <div className="space-10"></div>
                        <div className="et-progress">
                            <div className="ot-progress">
                                <div className="overflow">
                                    <span className="pname f-left">mobile development</span>
                                    <span className="ppercent f-right">70%</span>
                                </div>
                                <div className="iprogress">
                                    <div className="progress-bar" data-percent="70%"></div>
                                </div>
                            </div>
                            <div className="ot-progress">
                                <div className="overflow">
                                    <span className="pname f-left">web development</span>
                                    <span className="ppercent f-right">90%</span>
                                </div>
                                <div className="iprogress">
                                    <div className="progress-bar" data-percent="90%"></div>
                                </div>
                            </div>
                            <div className="ot-progress">
                                <div className="overflow">
                                    <span className="pname f-left">ui/ux design</span>
                                    <span className="ppercent f-right">60%</span>
                                </div>
                                <div className="iprogress">
                                    <div className="progress-bar" data-percent="60%"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/image2-about.png`} alt="" />
                </div>
            </div>
        </div>
    </section>
</div>
  )
}

export default About;