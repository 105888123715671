import React from 'react';
import { Link } from 'react-router-dom';
import { PayPalButtons } from '@paypal/react-paypal-js';

const Checkout = () => {
    
  const handleCreateOrder = (data, actions) => {
    return fetch('/api/create-order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        items: [
          // Example item structure, replace with your actual data
          { id: 'hoodie', name: 'Hoodie with Logo', quantity: 1, price: 39.99 },
          { id: 'tshirt', name: 'Dark T-Shirt with Logo', quantity: 1, price: 59.99 }
        ]
      })
    })
    .then(response => response.json())
    .then(order => order.id)
    .catch(err => console.error('Error creating order:', err));
  };

  const handleApproveOrder = (data, actions) => {
    return fetch(`/api/capture-order/${data.orderID}`, {
      method: 'POST'
    })
    .then(response => response.json())
    .then(details => {
      alert('Transaction completed by ' + details.payer.name.given_name);
      // Additional logic after payment is captured
    })
    .catch(err => console.error('Error capturing order:', err));
  };

  return (
    <div>
      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Checkout</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li><Link to="/">Home</Link></li>
                <li className="active">Checkout</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="checkout-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="checkout-coupon">
                  <div className="coupon-toggle">
                    <i className="far fa-window-maximize"></i>
                    Have a coupon? <Link className="showcoupon">Click here to enter your code</Link>
                  </div>
                  <form className="checkout-coupon-form" method="post">
                    <p>If you have a coupon code, please apply it below.</p>
                    <div className="form-group">
                      <input type="text" name="coupon_code" className="input-text" placeholder="Coupon code" id="coupon_code" value="" />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary" name="apply_coupon" value="Apply coupon">Apply coupon</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="billing-details">
                  <h3>Billing Details</h3>
                  <div className="billing-fields">
                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <label htmlFor="billing_first_name">First Name <abbr className="required" title="required">*</abbr></label>
                        <input type="text" className="form-control" name="billing_first_name" id="billing_first_name" placeholder="" value="" autoComplete="given-name" />
                      </div>
                      <div className="col-lg-6 form-group">
                        <label htmlFor="billing_last_name">Last Name <abbr className="required" title="required">*</abbr></label>
                        <input type="text" className="form-control" name="billing_last_name" id="billing_last_name" placeholder="" value="" autoComplete="family-name" />
                      </div>
                      <div className="col-lg-12 form-group">
                        <label htmlFor="billing_company">Company Name <span className="optional">(optional)</span></label>
                        <input type="text" className="form-control" name="billing_company" id="billing_company" placeholder="" value="" autoComplete="organization" />
                      </div>
                      <div className="col-lg-12 form-group">
                        <label htmlFor="billing_country">Country / Region <abbr className="required" title="required">*</abbr></label>
                        <select name="billing_country" id="billing_country" className="form-control select-country" autoComplete="country">
                          <option value="">Select a country / region…</option>
                          {/* Add all countries here */}
                          <option value="VN">Vietnam</option>
                          <option value="US">United States</option>
                          <option value="GB">United Kingdom</option>
                        </select>
                      </div>
                      <div className="col-lg-12 form-group">
                        <label htmlFor="billing_address_1">Street Address <abbr className="required" title="required">*</abbr></label>
                        <input type="text" className="form-control" name="billing_address_1" id="billing_address_1" placeholder="House number and street name" value="" autoComplete="address-line1" />
                      </div>
                      <div className="col-lg-12 form-group">
                        <label htmlFor="billing_postcode">Postcode / ZIP <span className="optional">(optional)</span></label>
                        <input type="text" className="form-control" name="billing_postcode" id="billing_postcode" placeholder="" value="" autoComplete="postal-code" />
                      </div>
                      <div className="col-lg-12 form-group">
                        <label htmlFor="billing_city">Town / City <abbr className="required" title="required">*</abbr></label>
                        <input type="text" className="form-control" name="billing_city" id="billing_city" placeholder="" value="" autoComplete="address-level2" />
                      </div>
                      <div className="col-lg-12 form-group">
                        <label htmlFor="billing_phone">Phone <abbr className="required" title="required">*</abbr></label>
                        <input type="tel" className="form-control" name="billing_phone" id="billing_phone" placeholder="" value="" autoComplete="tel" />
                      </div>
                      <div className="col-lg-12 form-group">
                        <label htmlFor="billing_email">Email Address <abbr className="required" title="required">*</abbr></label>
                        <input type="email" className="form-control" name="billing_email" id="billing_email" value="" autoComplete="email" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="additional-information">
                  <h3>Additional Information</h3>
                  <div className="additional-fields">
                    <div className="form-group">
                      <label htmlFor="order_comments">Order Notes <span className="optional">(optional)</span></label>
                      <textarea name="order_comments" className="form-control" id="order_comments" placeholder="Notes about your order, e.g. special notes for delivery." rows="4"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h3 className="order-review-heading">Your Order</h3>
                <table className="order-review-table">
                  <thead>
                    <tr>
                      <th className="product-name">Product</th>
                      <th className="product-total">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="cart-item">
                      <td className="product-name">Hoodie with Logo <strong className="product-quantity">× 1</strong></td>
                      <td className="product-total"><span className="amount">$39.99</span></td>
                    </tr>
                    <tr className="cart-item">
                      <td className="product-name">Dark T-Shirt with Logo <strong className="product-quantity">× 1</strong></td>
                      <td className="product-total"><span className="amount">$59.99</span></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr className="cart-subtotal">
                      <th>Subtotal</th>
                      <td><span className="amount">$99.98</span></td>
                    </tr>
                    <tr className="order-total">
                      <th>Total</th>
                      <td><strong><span className="amount">$99.98</span></strong></td>
                    </tr>
                  </tfoot>
                </table>
                <div id="payment" className="checkout-payment">
                  <div className="payment-method">
                    <PayPalButtons
                      createOrder={handleCreateOrder}
                      onApprove={handleApproveOrder}
                    />
                  </div>
                  <div className="place-order text-right">
                    <button type="submit" className="btn btn-primary" name="place_order" id="place_order">Place Order</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Checkout;
