import React from 'react';
import Slider from 'react-slick';

const IndustriesSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <section className="section-industris">
      <div className="container">
        <div className="col-md-12 text-center">
          <div className="ot-heading">
            <span>Our Reach</span>
            <h2 className="main-heading">Industries <br />Which We Serve</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="ot-industries">
          <Slider {...settings}>
            <div className="indus-item">
              <div className="item-inner">
                <div className="overlay"></div>
                <div className="i-image">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/b-image.png`} alt="Business & Finance" />
                </div>
                <div className="iinfo">
                  <h6>Business & Finance</h6>
                  <div className="itext">
                    The global economy is moving towards a new identity. In order to remain competitive and profitable, businesses are leveraging online platforms to grow.
                  </div>
                </div>
              </div>
            </div>
            <div className="indus-item">
              <div className="item-inner">
                <div className="overlay"></div>
                <div className="i-image">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/g-image.png`} alt="Industrial & Manufacturing" />
                </div>
                <div className="iinfo">
                  <h6>General & Manufacturing</h6>
                  <div className="itext">
                    The need for exposure and digital presence is what separates the top from the bottom of industry and manufacturing performers. 
                  </div>
                </div>
              </div>
            </div>
            <div className="indus-item">
              <div className="item-inner">
                <div className="overlay"></div>
                <div className="i-image">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/t-image.png`} alt="Information Technology" />
                </div>
                <div className="iinfo">
                  <h6>Information Technology</h6>
                  <div className="itext">
                    As a technology business, we understand the constant requirements of growth and technological evolution. We are by your side!
                  </div>
                </div>
              </div>
            </div>
            <div className="indus-item">
              <div className="item-inner">
                <div className="overlay"></div>
                <div className="i-image">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/r-image.png`} alt="Retail & Distribution" />
                </div>
                <div className="iinfo">
                  <h6>Retail & Distribution</h6>
                  <div className="itext">
                    Retail and distribution industries have never been in a more competitive market which is still growing day by day. Don't stand aside, stand out.
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default IndustriesSection;
