import axios from 'axios';

// Create an instance of axios with common configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001/api', // Fallback to localhost if env variable isn't set
  headers: {
    'Content-Type': 'application/json',
  },
});

// Utility function to handle contact form submission
export const sendContactForm = async (formData) => {
  try {
    const response = await api.post('/contact', formData); // POST request to '/contact'
    console.log('Response: ', response);
    return response.data;
  } catch (error) {
    console.error('Error sending contact form:', error);
    throw error; // Re-throw error so it can be handled in the component
  }
};

// Utility function to fetch enquiries
export const fetchEnquiries = async () => {
  try {
    const response = await api.get('/enquiries'); // GET request to '/enquiries'
    return response.data;
  } catch (error) {
    console.error('Error fetching enquiries:', error);
    throw error;
  }
};
