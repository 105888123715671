import React from 'react';
import { Link } from "react-router-dom";

const Faq = () => {
  return (
    <div>
       <div id="content" className="site-content">
            <div className="page-header flex-middle">
                <div className="container">
                    <div className="inner flex-middle">
                        <h1 className="page-title">FAQs</h1>
                        <ul id="breadcrumbs" className="breadcrumbs none-style">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">frequently Asked Questions</li>
                        </ul>    
                    </div>
                </div>
            </div>
            
            <section className="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="ot-heading">
                                <span>FAQ</span>
                                <h2 className="main-heading">Read Most <br />Frequent Questions</h2>
                            </div>
                            <div className="space-25"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="ot-accordions">
                                <div className="acc-item">
                                    <span className="acc-toggle">How To Choose A Good QA Consultant? 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>Choosing a good QA consultant involves looking for expertise in your specific tech stack, a track record of robust application testing, and a consultative approach that aligns with your development methodology.</p>
                                    </div>
                                </div>
                                <div className="acc-item">
                                    <span className="acc-toggle">How to Create a Chatbot to Fit Your Needs? 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>To create an effective chatbot, identify the key functionalities your users need, select the right platform and tools, and integrate it seamlessly with your existing support system to enhance customer interactions.</p>
                                    </div>
                                </div>
                                <div className="acc-item">
                                    <span className="acc-toggle">What is off page SEO link building? 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>Off-page SEO link building is the process of acquiring hyperlinks from other websites to your own. It helps improve your site’s visibility and ranking in search engines, essentially signaling relevance and trustworthiness to search engines.</p>
                                    </div>
                                </div>
                                <div className="acc-item">
                                    <span className="acc-toggle">How can I order web design for my website? 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>You can order web design by consulting with design professionals who understand your brand vision and user needs. Ensure they have a clear process for design, feedback, and revisions to align with your goals.</p>
                                    </div>
                                </div>
                                <div className="acc-item">
                                    <span className="acc-toggle">Best SEO Practices for Page Layouts 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>The best SEO practices for page layouts involve using a clean, organized structure that prioritizes user experience, incorporates responsive design, and optimizes loading speeds to improve search engine rankings.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ot-accordions">
                                <div className="acc-item">
                                    <span className="acc-toggle">How Does Off Site SEO Work? 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>Off-site SEO works by enhancing your website's reputation and authority through backlinks, social media strategy, and influencer engagements, which helps improve your site's position in search engine results pages (SERPs).</p>
                                    </div>
                                </div>
                                <div className="acc-item">
                                    <span className="acc-toggle">Why is SEO Link Building Important? 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>SEO link building is crucial as it is a major factor that search engines use to determine website authority and relevance, helping to increase organic search rankings and drive more traffic to your site.</p>
                                    </div>
                                </div>
                                <div className="acc-item">
                                    <span className="acc-toggle">Why is Researching Keywords Important? 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>Researching keywords is important because it helps you understand what your audience is searching for, allowing you to optimize your content to meet those needs and improve visibility on search engines.</p>
                                    </div>
                                </div>
                                <div className="acc-item">
                                    <span className="acc-toggle">Best SEO Practices for Page Layouts 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>The best SEO practices for page layouts focus on providing a user-friendly experience, employing a mobile-first approach, ensuring navigability, and strategically placing keywords to boost SEO effectiveness.</p>
                                    </div>
                                </div>
                                <div className="acc-item">
                                    <span className="acc-toggle">Best SEO Practices for High Quality Content 
                                        <i className="down flaticon-download-arrow"></i><i className="up flaticon-up-arrow"></i>
                                    </span>
                                    <div className="acc-content">
                                        <p>High-quality content is optimized for both search engines and users. It should be engaging, informative, and well-structured, using keywords appropriately to enhance readability and search engine rankings.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
            <section className="faq-support">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cta-faq">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 mb-4 mb-md-0">
                                        <div className="ot-heading">
                                            <span>We Carry more Than Just Good Coding Skills</span>
                                            <h2 className="main-heading">Let's Build Your Website!</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 align-self-end">
                                        <div className="ot-button text-center text-md-right">
                                            <Link to="/contact" className="octf-btn octf-btn-primary">Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-120"></div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="ot-heading">
                                <span>support center</span>
                                <h2 className="main-heading">Our Support Team <br />Will Always Assist You</h2>
                            </div>
                        </div>
                    </div>
                    <div className="space-35"></div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <span className="number-box">01</span>
                                        <div className="inner">
                                          <p>Entrust full-cycle implementation of your software product to our experienced Business Analists, UI/UX designers, Developers.</p>
                                          <Link to="/web-development" className="btn-details"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">01</span>
                                        <h3>Web Applications</h3>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/support1.jpg`} alt="For Partners" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <span className="number-box">02</span>
                                        <div className="inner">
                                          <p>Mobility has become a need for businesses, be mobile, be efficient!</p>
                                          <Link to="/web-development" className="btn-details"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">02</span>
                                        <h3>Mobile Applications</h3>
                                        <img ssrc={`${process.env.PUBLIC_URL}/assets/images/support2.jpg`} alt="For Customers" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <span className="number-box">03</span>
                                        <div className="inner">
                                          <p>You have already found what you are looking for but it needs some small tweaks or speak to another platform. We have the skills you require.</p>
                                          <Link to="/mobile-development" className="btn-details"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">03</span>
                                        <h3>Software Integrations</h3>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/support3.jpg`} alt="For Startups" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-120"></div>
                </div>
            </section>
            
        </div>
    </div>
  )
}

export default Faq