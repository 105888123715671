import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Sidebar.css';
import { useCart } from '../../context/CartContext';

const Sidebar = ({ onSearch, onCategorySelect, onTagSelect, onPriceFilter, products }) => {
  const { cart, dispatch } = useCart();
  const [searchTerm, setSearchTerm] = useState('');
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueTags, setUniqueTags] = useState([]);

  useEffect(() => {
    if (products.length > 0) {
      const minPrice = Math.min(...products.map(product => Math.max(product.price, product.oldPrice || 0)));
      const maxPrice = Math.max(...products.map(product => Math.max(product.price, product.oldPrice || 0)));
      setPriceRange([minPrice, maxPrice]);

      // Get unique categories
      const categories = [...new Set(products.flatMap(product => product.categories))];
      setUniqueCategories(categories);

      // Get unique tags
      const tags = [...new Set(products.flatMap(product => product.tags))];
      setUniqueTags(tags);
    }
  }, [products]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (onSearch) {
      onSearch(term);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(searchTerm);
    }
  };

  const handleCategorySelect = (category) => {
    if (onCategorySelect) {
      onCategorySelect(category);
    }
  };

  const handleTagSelect = (tag) => {
    if (onTagSelect) {
      onTagSelect(tag);
    }
  };

  const handlePriceChange = (range) => {
    setPriceRange(range);
    if (onPriceFilter) {
      onPriceFilter({ min: range[0], max: range[1] });
    }
  };

  const removeFromCart = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id } });
  };

  const cartTotal = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  // Filter popular products
  const popularProducts = products.filter(product => product.popular);

  return (
    <div className="widget-area primary-sidebar col-lg-3 col-md-12 col-sm-12 modern-sidebar">
      <aside id="search-2" className="widget widget_search">
        <form role="search" method="get" id="search-form" className="search-form" onSubmit={handleSearchSubmit}>
          <input
            type="search"
            className="search-field"
            placeholder="Search…"
            value={searchTerm}
            name="s"
            onChange={handleSearchChange}
          />
          <button type="submit" className="search-submit"><i className="flaticon-search"></i></button>
        </form>
      </aside>
      <aside className="widget widget_categories">
        <h5 className="widget-title">Categories</h5>
        <ul>
          {uniqueCategories.map(category => (
            <li key={category}>
              <Link to="#" onClick={() => handleCategorySelect(category)}>{category}</Link>
            </li>
          ))}
        </ul>
      </aside>
      <aside className="widget widget_products">
        <h5 className="widget-title">Popular</h5>
        <ul className="popular-product clearfix">
          {popularProducts.map(product => (
            <li key={product.id} className="clearfix">
              <div className="thumb">
                <Link to={product.link}><img src={product.image} alt={product.name} /></Link>
              </div>
              <div className="entry-header">
                <h6><Link to={product.link}>{product.name}</Link></h6>
                <span className="price-product">
                  {product.oldPrice && <del><span className="amount"><span>$</span>{product.oldPrice}</span></del>}
                  <ins><span className="amount"><span>$</span>{product.price}</span></ins>
                </span>
              </div>
            </li>
          ))}
        </ul>
      </aside>
      <aside className="widget woocommerce widget_price_filter">
        <h5 className="widget-title">Filter by price</h5>
        <form method="get">
          <div className="price_slider_wrapper">
            <div className="price_slider_label">
              <span className="price_slider_min">${priceRange[0].toFixed(2)}</span>
              <span className="price_slider_max">${priceRange[1].toFixed(2)}</span>
            </div>
            <Slider
              range
              min={Math.min(...products.map(product => Math.max(product.price, product.oldPrice || 0)))}
              max={Math.max(...products.map(product => Math.max(product.price, product.oldPrice || 0)))}
              value={priceRange}
              onChange={handlePriceChange}
            />
          </div>
        </form>
      </aside>
      <aside className="widget widget_tag_cloud">
        <h5 className="widget-title">Tags</h5>
        <div className="tagcloud">
          {uniqueTags.map(tag => (
            <Link to="#" key={tag} onClick={() => handleTagSelect(tag)}>{tag}</Link>
          ))}
        </div>
      </aside>
      <aside className="widget woocommerce widget_shopping_cart">
        <h5 className="widget-title">Shopping Cart</h5>
        <div className="widget_shopping_cart_content">
          <ul className="cart_list">
            {cart.map(item => (
              <li key={item.id} className="clearfix">
                <Link to="#" className="remove remove_from_cart_button" onClick={() => removeFromCart(item.id)}>×</Link>
                <div className="thumb">
                  <Link to={item.link}><img src={item.image} alt={item.name} /></Link>
                </div>
                <div className="entry-header">
                  <h6><Link to={item.link}>{item.name}</Link></h6>
                  <span className="price-product"><span className="quantity">{item.quantity} × </span><span className="amount"><span>$</span>{item.price.toFixed(2)}</span></span>
                </div>
              </li>
            ))}
          </ul>
          <p className="woocommerce-mini-cart__total total">
            <strong>Subtotal:</strong> <span className="amount"><span>$</span>{cartTotal.toFixed(2)}</span>
          </p>
          <p className="woocommerce-mini-cart__buttons buttons">
            <Link to="/cart" className="octf-btn octf-btn-primary button wc-forward">View cart</Link>
            <Link to="/checkout" className="octf-btn button checkout wc-forward">Checkout</Link>
          </p>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
