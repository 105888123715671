import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ProductCard from "../components/ProductCard/ProductCard";
import Sidebar from "../components/Sidebar/Sidebar";

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTag, setSelectedTag] = useState('');
  const productContainerRef = useRef(null);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 });
  const [sortOption, setSortOption] = useState("menu_order");

  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/products`);
        setProducts(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    filterProducts(searchTerm, selectedCategory, selectedTag, priceRange, sortOption);
  }, [searchTerm, selectedCategory, selectedTag, priceRange, sortOption]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    productContainerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
  };

  const handlePriceFilter = (range) => {
    setPriceRange(range);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const filterProducts = (
    term = "",
    category = "",
    tag = "",
    range = { min: 0, max: 1000 },
    sort = "menu_order"
  ) => {
    const lowercasedTerm = term.toLowerCase();
    let filtered = products.filter(
      (product) =>
        product.name.toLowerCase().includes(lowercasedTerm) &&
        (category ? product.categories.includes(category) : true) &&
        (tag ? product.tags.includes(tag) : true) &&
        product.price >= range.min &&
        product.price <= range.max
    );

    switch (sort) {
      case "popularity":
        filtered = filtered.sort((a, b) => b.popularity - a.popularity);
        break;
      case "rating":
        filtered = filtered.sort((a, b) => b.rating - a.rating);
        break;
      case "date":
        filtered = filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
        break;
      case "price":
        filtered = filtered.sort((a, b) => a.price - b.price);
        break;
      case "price-desc":
        filtered = filtered.sort((a, b) => b.price - a.price);
        break;
      default:
        break;
    }

    setFilteredProducts(filtered);
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setSelectedCategory("");
    setSelectedTag('');
    setPriceRange({ min: 0, max: 1000 });
    setSortOption("menu_order");
    setFilteredProducts(products);
  };

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  return (
    <div>
      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle" ref={productContainerRef}>
              <h1 className="page-title">Shop</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Shop</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="shop-catalog">
          <div className="container">
            <div className="row">
              <Sidebar
                onSearch={handleSearch}
                onCategorySelect={handleCategorySelect}
                onTagSelect={handleTagSelect}
                onPriceFilter={handlePriceFilter}
                products={products}
              />
              <div className="col-lg-9 col-md-12 order-first order-lg-last mb-lg-0 mb-5">
                <p className="woocommerce-result-count">
                  Showing {indexOfFirstProduct + 1}–
                  {Math.min(indexOfLastProduct, filteredProducts.length)} of{" "}
                  {filteredProducts.length} results
                </p>
                <form className="woocommerce-ordering" method="get">
                  <select
                    name="orderby"
                    className="orderby"
                    aria-label="Shop order"
                    onChange={handleSortChange}
                  >
                    <option value="menu_order" selected="selected">
                      Default sorting
                    </option>
                    <option value="popularity">Sort by popularity</option>
                    <option value="rating">Sort by average rating</option>
                    <option value="date">Sort by latest</option>
                    <option value="price">Sort by price: low to high</option>
                    <option value="price-desc">Sort by price: high to low</option>
                  </select>
                  <input type="hidden" name="paged" value="1" />
                  <button
                    type="button"
                    onClick={handleClearFilter}
                    className="clear-filter-btn"
                  >
                    Clear Search
                  </button>
                </form>
                <div className="product">
                  <div className="row">
                    {currentProducts.map((product) => (
                      <ProductCard key={product.id} product={product} />
                    ))}
                  </div>
                </div>
                <ul className="page-pagination text-center mt-3 none-style">
                  {Array.from({ length: totalPages }, (_, i) => (
                    <li key={i}>
                      <Link
                        className="page-numbers"
                        to="#"
                        onClick={() => handlePageChange(i + 1)}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                </ul>
                <p className="woocommerce-result-count text-center">
                  Showing {indexOfFirstProduct + 1}–
                  {Math.min(indexOfLastProduct, filteredProducts.length)} of{" "}
                  {filteredProducts.length} results
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
