import React from "react";
import { Link } from 'react-router-dom';

const PortfolioDetails = () => {
  return (
    <div>
      <div id="content" className="site-content">
        <div className="page-header flex-middle">
          <div className="container">
            <div className="inner flex-middle">
              <h1 className="page-title">Portfolio Single</h1>
              <ul id="breadcrumbs" className="breadcrumbs none-style">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Portfolio Single</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="portfolio-single">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>App for Virtual Reality</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 mb-lg-0">
                <div className="project-detail">
                  <span>PUBLISHED:</span>
                  <strong>April 20, 2019</strong>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 mb-sm-0">
                <div className="project-detail">
                  <span>CATEGORY:</span>
                  <strong>Design / Ideas</strong>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 mb-sm-0">
                <div className="project-detail">
                  <span>CLIENT:</span>
                  <strong>ThemeModern</strong>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="otf-social-share clearfix shape-circle">
                  <Link className="share-facebook" to="facebook.com" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link className="share-twitter" to="twitter.com" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link
                    className="share-pinterest"
                    to="pinterest.com"
                    target="_blank"
                  >
                    <i className="fab fa-pinterest-p"></i>
                  </Link>
                  <Link className="share-linkedin" to="linkedin.com" target="_blank">
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="space-50"></div>
            <div className="row">
              <div className="col-md-12">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/projects/project-single.jpg`}
                  alt=""
                  className="single-project-img"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>How it Works </h3>
                <p>
                  <span className="drop-cap">
                    <span className="drop-cap-letter">M</span>
                  </span>{" "}
                  obile apps have already penetrated every sphere of our lives
                  and every imaginable business niche. Naturally, the appeal of
                  mobile apps for its market potential cannot fade away anytime
                  soon, at least until another great technology can replace
                  mobile apps. development company and its business clients. So,
                  what about the failure of other apps? What are the key factors
                  responsible for the failure of millions of apps? Well, apart
                  from the ill-conceived app idea and development and design
                  flaws many apps simply fail because of their{" "}
                  <strong>pre-launch and post-launch shortcomings.</strong>
                </p>
                <p>
                  Front-end development essentially focuses and works on the
                  visual perceptions of a website. It’s about the look, load
                  time, pattern and functionality of the components of a
                  website. Front-End is a mix of programming and layout that
                  powers the visuals and interactions on the web. The talented
                  Front-end developers provide turnkey Front-End development
                  services whether you are planning to change the Front-End from
                  older technology to newer one, for example Angular.
                </p>
                <blockquote>
                  <p>
                    I cannot give you the formula for success, but I can give
                    you the formula for failure. <br /> It is: Try to please
                    everybody.
                    <br />
                    <cite>– Herbert Bayard Swope</cite>
                  </p>
                </blockquote>
                <div className="simple-slider owl-theme owl-carousel">
                  <div className="projects-slide-item">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/project-details-s1.png`} alt="" />
                  </div>
                  <div className="projects-slide-item">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/project-details-s2.png`} alt="" />
                  </div>
                  <div className="projects-slide-item">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/project-details-s1.png`} alt="" />
                  </div>
                  <div className="projects-slide-item">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/project-details-s2.png`} alt="" />
                  </div>
                </div>
                <h3>Process & Results </h3>
                <p>
                  But while DevOps as a development approach remained
                  uncontested, the security aspects of the app development
                  process largely remained under the shadow. There was a
                  perception that along with the operations, app security should
                  equally be a part of the development process. With the demand
                  for integrating security with the product life cycle, the
                  DevSecOps approach has emerged.{" "}
                  <span className="text-second text-decoration-line">
                    This approach has already proven{" "}
                  </span>{" "}
                  to play an instrumental role in reducing the security
                  vulnerabilities of the apps. While integrating the security
                  with the DevOps approach has always been of high demand, this
                  requires a completely different range of processes and tools.
                  DevOps is already known to boast of an agile development
                  methodology.
                </p>
                <p>
                  While integrating the security with the DevOps approach has
                  always been of high demand, this requires a completely
                  different range of processes and tools. DevOps is already
                  known to boast of an agile development methodology. Though the
                  companies embracing DevSecOps development methodology are only
                  a few in numbers, in the time to come many companies working
                  with DevOps approach can actually embrace this new methodology
                  for giving their projects.
                </p>
                <div className="blog-post projects-meta">
                  <div className="entry-footer clearfix">
                    <div className="widget_tag_cloudd tagcloud">
                      <Link to="#">startup</Link>
                      <Link to="#">technology</Link>
                      <Link to="#">news</Link>
                    </div>
                    <span className="sl-wrapper">
                      <Link to="#" className="sl-button sl-button-1263" title="Like">
                        <span className="sl-icon">
                          <i className="far fa-heart"></i>
                        </span>
                        <span className="sl-count">125</span>
                      </Link>
                    </span>
                  </div>
                </div>
                <div id="comments" className="comments-area project-comtmnents">
                  <h2 className="comments-title">
                    Comments <span>(02)</span>
                  </h2>
                  <ul className="comment-list">
                    <li className="comment even thread-even comment-item">
                      <article className="comment-wrap clearfix">
                        <div className="gravatar">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/comment-1.jpg`}
                            alt="Pablo Villalpando"
                            className="avatar"
                          />
                        </div>
                        <div className="comment-content">
                          <div className="comment-meta">
                            <h6 className="comment-author">Liza Benedict</h6>
                            <span className="comment-time">2 Days ago</span>
                            <div className="comment-reply">
                              <Link className="comment-reply-link" to="#">
                                Reply
                              </Link>
                            </div>
                          </div>
                          <div className="comment-text">
                            <p>
                              Toris Technologies is always changing so leaving
                              the strategy and tactics to Onum has more than
                              paid for itself. We estimate ROI is over 10 to 1 –
                              I can’t say enough about this team.
                            </p>
                          </div>
                        </div>
                      </article>
                    </li>
                  </ul>
                  <ul className="children">
                    <li className="comment odd alt comment-item">
                      <article className="comment-wrap clearfix">
                        <div className="gravatar">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/comment-2.jpg`}
                            alt="Pablo Villalpando"
                            className="avatar"
                          />
                        </div>

                        <div className="comment-content">
                          <div className="comment-meta">
                            <h6 className="comment-author">Frank Barry</h6>
                            <span className="comment-time">2 Days ago</span>
                            <div className="comment-reply">
                              <Link className="comment-reply-link" to="#">
                                Reply
                              </Link>
                            </div>
                          </div>
                          <div className="comment-text">
                            <p>
                              Toris Technologies has been extremely consistent
                              and reliable through our entire engagement. Our
                              results speak for themselves.
                            </p>
                          </div>
                        </div>
                      </article>
                    </li>
                  </ul>
                </div>
                <form
                  action="teamcontact.php"
                  method="post"
                  className="wpcf7-form init"
                >
                  <div className="member-form comment-respond">
                    <h2>Leave a comment</h2>
                    <p className="font14">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <div className="row">
                      <p className="col-md-6">
                        <input
                          type="text"
                          name="name"
                          value=""
                          size="40"
                          placeholder="Your Name *"
                          required
                        />
                      </p>
                      <p className="col-md-6">
                        <input
                          type="email"
                          name="email"
                          value=""
                          size="40"
                          placeholder="Your Email *"
                          required
                        />
                      </p>
                    </div>
                    <p>
                      <input
                        type="text"
                        name="site"
                        value=""
                        size="40"
                        placeholder="Website"
                      />
                    </p>
                    <p>
                      <textarea
                        name="message"
                        cols="40"
                        rows="10"
                        placeholder="Message..."
                        required
                      ></textarea>
                    </p>
                    <p>
                      <button type="submit" className="octf-btn octf-btn-primary">
                        Send Message
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PortfolioDetails;
