import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { Navbar } from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import WhyChooseUs from "./pages/WhyChooseUs";
import Team from "./pages/Team";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import Shop from "./pages/Shop";
import NotFound from "./pages/NotFound";
import NotAuthorized from "./pages/NotAuthorized";
import WebDevelopment from "./pages/WebDevelopment";
import Checkout from "./pages/Checkout";
import Cart from "./pages/Cart";
import DigitalMarketing from "./pages/DigitalMarketing";
import MobileDevelopment from "./pages/MobileDevelopment";
import NativeDevelopment from "./pages/NativeDevelopment";
import PortfolioDetails from "./pages/PortfolioDetails";
import Post from "./pages/Post";
import SingleProduct from "./pages/SingleProduct";
import SoftwareIntegrations from "./pages/SoftwareIntegrations";
import ComingSoon from "./pages/ComingSoon";
import AdminDashboard from "./pages/AdminDashboard";
import AdminProductCategories from "./components/AdminProductCategories/AdminProductCategories";
import AdminProductTags from "./components/AdminProductTags/AdminProductTags";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminProtectedRoute from "./components/AdminProtectedRoutes/AdminProtectedRoutes"; // Imported AdminProtectedRoute
import AdminUsersManagement from "./components/AdminUsersManagement/AdminUsersManagement";
import EmailVerified from "./pages/EmailVerified";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

function App() {

  // useEffect(() => {
  //   ReactGA.initialize('YOUR_TRACKING_ID');
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <AuthProvider>
      <PayPalScriptProvider
        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
      >
        <Router>
          <div className="App">
            <Navbar />
            <MainContent />
          </div>
        </Router>
      </PayPalScriptProvider>
    </AuthProvider>
  );
}

const MainContent = () => {
  const location = useLocation();

  const excludeFooterRoutes = ["/admin", "/login", "/register"];

  const shouldExcludeFooter = excludeFooterRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <>
      <Routes>
        {/* General Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/why-choose-us" element={<WhyChooseUs />} />
        <Route path="/team" element={<Team />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        {/* Portfolio Routes */}
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
        {/* Service Routes */}
        <Route path="/web-development" element={<WebDevelopment />} />
        <Route path="/digital-marketing" element={<DigitalMarketing />} />
        <Route path="/mobile-development" element={<MobileDevelopment />} />
        <Route path="/native-development" element={<NativeDevelopment />} />
        <Route
          path="/software-integrations"
          element={<SoftwareIntegrations />}
        />
        {/* Blog Routes */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/post/:postId" element={<Post />} />
        {/* Shop Routes */}
        <Route path="/shop" element={<Shop />} />
        <Route path="/single-product" element={<SingleProduct />} />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute>
          }
        />
        {/* Admin Pages */}
        <Route
          path="/admin"
          element={
            <AdminProtectedRoute>
              <AdminDashboard />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/categories"
          element={
            <AdminProtectedRoute>
              <AdminProductCategories />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/tags"
          element={
            <AdminProtectedRoute>
              <AdminProductTags />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <AdminProtectedRoute>
              <AdminUsersManagement />
            </AdminProtectedRoute>
          }
        />
        {/* Auth Pages */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verification-success" element={<EmailVerified />} />
        {/* Invalid Route */}
        <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
        {/* Access denied */}
        <Route path="/not-authorized" element={<NotAuthorized />} /> {/* For non authed users */}
      </Routes>
      {!shouldExcludeFooter && <Footer />}
    </>
  );
};

export default App;
