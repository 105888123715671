import React from 'react';
import { Link } from 'react-router-dom';
import { HomeBanner } from '../components/HomeBanner/HomeBanner';
import IndustriesSection from '../components/IndustriesSection/IndustriesSection';

const Home = () => {
  return (
    <div>
        <HomeBanner />
        <section className="section-business">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ot-heading mb-0">
                                <span>We transform businesses</span>
                                <h2 className="main-heading">For Over 7 Years. <br />For Thousands of Users</h2>
                            </div>
                        </div>
                        <div className="col-lg-7 align-self-end">
                            <p className="mb-0">The development of reliable and scalable software solutions for any OS, browser and device. We bring together deep industry expertise and the latest IT advancements to deliver custom solutions and products that perfectly fit the needs and behavior of their users.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-clent-h2">
                <div className="container">
                    <div className="row mt--290 justify-content-center">
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 mb-lg-0">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <span className="number-box">01</span>
                                        <div className="inner card-text">
                                            <p>Entrust full-cycle implementation of your software product to our experienced Business Analists, UI/UX designers, Developers.</p>
                                            <Link to="/web-development" className="btn-details"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">01</span>
                                        <h3>Custom Software</h3>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flipbox1.jpg`} alt="Custom Software" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 mb-lg-0">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <span className="number-box">02</span>
                                        <div className="inner card-text">
                                            <p>We take your business seriously! Let us take care of your website visitors user journey and turn them from visitors to leads.</p>
                                            <Link to="/web-development" className="btn-details"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">02</span>
                                        <h3>Business Websites</h3>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flipbox5.png`} alt="Business Websites" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <span className="number-box">03</span>
                                        <div className="inner card-text">
                                            <p>Mobility has become a need for businesses, be mobile, be efficient!</p>
                                            <Link to="/mobile-development" className="btn-details"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">03</span>
                                        <h3>Mobile Apps</h3>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flipbox3.jpg`} alt="Mobile Application Development" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="support-box">
                                <div className="inner-box">
                                    <div className="overlay flex-middle">
                                        <span className="number-box">04</span>
                                        <div className="inner card-text">
                                            <p>Stop using unreliable plugins to automate your business logic. We can customise every step to cater for your business needs.</p>
                                            <Link to="/digital-marketing" className="btn-details"><i className="flaticon-right-arrow-1"></i> LEARN MORE</Link>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <span className="number-box">04</span>
                                        <h3>Software Integrations</h3>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flipbox4.png`} alt="Digital Marketing" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-90"></div>

                    <div className="section-inner-about">
                        <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="left-img">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/image1-home2.png`} alt="" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="right-content">
                                    <div className="ot-heading">
                                        <span>About Company</span>
                                        <h2 className="main-heading">Your Partner for <br />Software Innovation</h2>
                                    </div>
                                    <p>Toris Technologies is the partner of choice for many leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services.</p>
                                    <p><em className="text-dark"><strong>We can help to maintain and modernise your IT infrastructure and solve various infrastructure-specific issues a business may face.</strong></em></p>
                                    <div className="space-20"></div>
                                    <div className="ot-button">
                                        <Link to="/about-us" className="octf-btn octf-btn-primary">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-technology">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="technology-left">
                                <div className="ot-heading">
                                    <span className="text-primary-light">technology index</span>
                                    <h2 className="main-heading text-white">Improve and Innovate with the Tech Trends</h2>
                                </div>
                                <p>Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.</p>
                                <ul className="style-none text-white">
                                    <li><i className="fas fa-check"></i> Application Development</li>
                                    <li><i className="fas fa-check"></i> BI Consulting and Implementation</li>
                                    <li><i className="fas fa-check"></i> Machine and Deep Learning</li>
                                    <li><i className="fas fa-check"></i> Data Quality Management</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row tech-box-grid">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <Link className="tech-box text-center" to="/web-development" target="_blank">
                                        <div className="icon-main"><span className="flaticon-code-1"></span></div>
                                        <h5>WEB</h5>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <Link className="tech-box text-center" to="/mobile-development">
                                        <div className="icon-main"><span className="flaticon-android"></span></div>
                                        <h5>Android</h5>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <Link className="tech-box text-center" to="/mobile-development">
                                        <div className="icon-main"><span className="flaticon-apple"></span></div>
                                        <h5>IOS</h5>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <Link className="tech-box text-center mb-0" to="/software-integrations">
                                        <div className="icon-main"><span className="flaticon-iot"></span></div>
                                        <h5>IOT</h5>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <Link className="tech-box text-center mb-0" to="/native-development">
                                        <div className="icon-main"><span className="flaticon-time-and-date"></span></div>
                                        <h5>Wearalables</h5>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <Link className="tech-box text-center mb-0" to="/mobile-development">
                                        <div className="icon-main"><span className="flaticon-tv"></span></div>
                                        <h5>TV</h5>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-case-study">
                <div className="container">
                    <div className="cta-h2">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 mb-4 mb-md-0">
                                <div className="ot-heading">
                                    <span>We Carry more Than Just Good Coding Skills</span>
                                    <h2 className="main-heading">Let's Build Your Website!</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 align-self-end">
                                <div className="ot-button text-center text-md-right">
                                    <Link to="/contact" className="octf-btn octf-btn-primary">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ot-heading mb-0">
                                <span>Latest Projects</span>
                                <h2 className="main-heading">Some of Our Projects</h2>
                            </div>
                        </div>
                        <div className="col-lg-5 align-self-end">
                            <p className="mb-0">We’ve exceled our experience in a wide range of industries to bring valuable insights and provide our customers.</p>
                        </div>
                        <div className="col-lg-2 align-self-end">
                            <div className="ot-button">
                                <Link to="/portfolio" className="btn-details"><i className="flaticon-right-arrow-1"></i>VIEW ALL PROJECTS</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="project-filter-wrapper pt-50">
                                <div className="projects-grid projects-style-1 projects-col3 projects-no-gaps">
                                    
                                    <div className="project-item design ideas">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/720x720/project1-720x720.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info ">
                                                <Link className="overlay" to="/portfolio-details"></Link>                                
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Customer Realtionship Management Applications</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Design</Link><span>/</span>
                                                        <Link to="#">Ideas</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                
                                    <div className="project-item development">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/720x720/project2-720x720.jpg`} alt="" />                                
                                                </Link>
                                            </div>
                                            <div className="portfolio-info ">
                                                <Link className="overlay" to="/portfolio-details"></Link>                               
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Mobile Car Care Solutions App</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Development</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                
                                    <div className="project-item ideas technology">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/720x720/project3-720x720.jpg`} alt="" />                                
                                                </Link>
                                            </div>
                                            <div className="portfolio-info">
                                                <Link className="overlay" to="/portfolio-details"></Link>
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Document Management Portal</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Ideas</Link><span>/</span>
                                                        <Link to="#">Technology</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                
                                    <div className="project-item design ideas">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/720x720/project4-720x720.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info ">
                                                <Link className="overlay" to="/portfolio-details"></Link> 
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">eCommerce Website</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Design</Link><span>/</span>
                                                        <Link to="#">Ideas</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                
                                    <div className="project-item development ideas">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/720x720/project6-720x720.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info ">
                                                <Link className="overlay" to="/portfolio-details"></Link>
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Modern Bsuiness Websites</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Development</Link><span>/</span>
                                                        <Link to="#">Ideas</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                
                                    <div className="project-item development">
                                        <div className="projects-box">
                                            <div className="projects-thumbnail">
                                                <Link to="/portfolio-details">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/projects/720x720/project5-720x720.jpg`} alt="" />
                                                </Link>
                                            </div>
                                            <div className="portfolio-info ">
                                                <Link className="overlay" to="/portfolio-details"></Link>                             
                                                <div className="portfolio-info-inner">
                                                    <h5><Link to="/portfolio-details">Dynamic Applications</Link></h5>
                                                    <p className="portfolio-cates">
                                                        <Link to="#">Development</Link>
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <IndustriesSection />

            <section className="section-consultation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p>Drop us a line! We are here to answer your questions</p>
                            <h2>NEED A CONSULTATION?</h2>
                            <div className="ot-button">
                                <Link to="/contact" className="octf-btn octf-btn-primary">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  )
}

export default Home;